import React from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import Auth from "./Auth";
import Register from "../../components/authentication/Register";

const RegisterView = () => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{t("activate_account_tab_title")}</title>
      </Helmet>
      <div className="d-flex flex-wrap login-view" style={{ width: 100 + "%" }}>
        <Auth />
        <div className="right-auth-page">
          <Register />
        </div>
      </div>
    </>
  );
};

export default RegisterView;
