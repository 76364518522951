import Linkedin from "../../../assets/images/icons/Linkedin.svg";
import Facebook from "../../../assets/images/icons/facebook-icon.svg";

import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation("");

  return (
    <footer className="footer-container mw-100 databank-footer">
      <div className="footer-copyright">
        <div className="footer-icons">
          <a
            href="https://www.linkedin.com/company/albanian-business-partner-sh.p.k/"
            target="_blank"
            rel="noreferrer"
          >
            <img src={Linkedin} alt="Logo" height={25} />
          </a>
          <a
            href="https://www.facebook.com/www.abp.al/"
            target="_blank"
            rel="noreferrer"
          >
            <img src={Facebook} alt="Logo" height={25} />
          </a>
        </div>
        <div className="developed">
          <p>
            {t("homepage_footer_copyright")} |{t("homepage_footer_powered")}
            &#10084;&#65039;
            {t("homepage_footer_powered_by")}
            <a href="https://abp.al/en/" target="_blank" rel="noreferrer">
              {t("homepage_footer_company_name")}
            </a>
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
