import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import ValidationMessage from "../../helpers/ValidationMessage";

import * as Request from "../../api/Request";
import * as ApiUrl from "../../api/ApiUrl";
import * as Message from "../../helpers/Messages";

const ContactUs = () => {
  const { t } = useTranslation();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [errorList, setErrorList] = useState([]);
  const [btnLoading, setBtnLoading] = useState(false);

  const contactUsData = (e) => {
    e.preventDefault();
    setBtnLoading(true);

    const contactData = {
      first_name: firstName,
      last_name: lastName,
      email: email,
      subject: subject,
      message: message,
    };

    Request.postRequestLogin(ApiUrl.contactUs, contactData).then((response) => {
      setBtnLoading(false);
      if (response.status === 422) {
        setErrorList(response.data);
      } else if (response.status === 201) {
        setErrorList([]);
        setFirstName("");
        setLastName("");
        setEmail("");
        setSubject("");
        setMessage("");
        Message.successMsg(response.data.success);
      } else {
        Message.errorMsg(t("error_message"));
      }
    });
  };

  return (
    <>
      <div className="pt-md-5" id="contactForm">
        <div className="py-5">
          <div className="partial-title d-flex justify-content-center pb-md-5">
            <h1>{t("homepage_contact_us_title")}</h1>
          </div>
          <div className="form-container container mw-25 w-100">
            <form className="w-100" onSubmit={(e) => contactUsData(e)}>
              <div className="row">
                <div className="form-block col-lg-6 col-md-6 col-sm-12 col-12">
                  <label>{t("homepage_contact_us_first_name_label")}</label>
                  <input
                    type="text"
                    name="first_name"
                    value={firstName}
                    placeholder={t(
                      "homepage_contact_us_first_name_placeholder"
                    )}
                    className={
                      "form-input" +
                      (errorList.first_name ? " input-error" : "")
                    }
                    autoComplete="off"
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                  {errorList.first_name && (
                    <ValidationMessage message={errorList.first_name[0]} />
                  )}
                </div>
                <div className="form-block col-lg-6 col-md-6 col-sm-12 col-12">
                  <label>{t("homepage_contact_us_last_name_label")}</label>
                  <input
                    type="text"
                    name="last_name"
                    value={lastName}
                    placeholder={t("homepage_contact_us_last_name_placeholder")}
                    className={
                      "form-input" + (errorList.last_name ? " input-error" : "")
                    }
                    autoComplete="off"
                    onChange={(e) => setLastName(e.target.value)}
                  />
                  {errorList.last_name && (
                    <ValidationMessage message={errorList.last_name[0]} />
                  )}
                </div>
              </div>
              <div className="row">
                <div className="form-block col-lg-6 col-md-6 col-sm-12 col-12">
                  <label>{t("homepage_contact_us_email_label")}</label>
                  <input
                    type="email"
                    name="email"
                    value={email}
                    placeholder={t("homepage_contact_us_email_placeholder")}
                    className={
                      "form-input" + (errorList.email ? " input-error" : "")
                    }
                    autoComplete="off"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  {errorList.email && (
                    <ValidationMessage message={errorList.email[0]} />
                  )}
                </div>
                <div className="form-block col-lg-6 col-md-6 col-sm-12 col-12">
                  <label>{t("homepage_contact_us_subject_label")}</label>
                  <input
                    type="text"
                    name="subject"
                    value={subject}
                    placeholder={t("homepage_contact_us_subject_placeholder")}
                    className={
                      "form-input" + (errorList.subject ? " input-error" : "")
                    }
                    autoComplete="off"
                    onChange={(e) => setSubject(e.target.value)}
                  />
                  {errorList.subject && (
                    <ValidationMessage message={errorList.subject[0]} />
                  )}
                </div>
              </div>
              <div className="row">
                <div className="form-block col-12 marc-confirm-records">
                  <label>{t("homepage_contact_us_message_label")}</label>
                  <textarea
                    name="message"
                    value={message}
                    placeholder={t("homepage_contact_us_message_placeholder")}
                    rows="4"
                    className={
                      "form-input" + (errorList.message ? " input-error" : "")
                    }
                    onChange={(e) => setMessage(e.target.value)}
                  ></textarea>
                  {errorList.message && (
                    <ValidationMessage message={errorList.message[0]} />
                  )}
                </div>
              </div>
              {btnLoading ? (
                <button type="button" className="button-blue has-icon">
                  <i className="spinner-icon" />
                  {t("homepage_contact_us_button_loading")}
                </button>
              ) : (
                <button type="submit" className="button-blue">
                  {t("homepage_contact_us_send_message_button")}
                </button>
              )}
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactUs;
