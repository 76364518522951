import React, { useState } from "react";
import EditProfile from "./EditProfile";
import ChangePassword from "./ChangePassword";
import Loading from "../../../helpers/Loading";

const Profile = () => {
  const [loading, setLoading] = useState(false);
  const handleSetLoading = (newState) => {
    setLoading(newState);
  };

  return (
    <>
      {loading ? (
        <div className="sites-list">
          <div className="text-center m-auto">
            <Loading />
          </div>
        </div>
      ) : (
        <>
          <EditProfile onSetLoading={handleSetLoading} />
          <ChangePassword />
        </>
      )}
    </>
  );
};

export default Profile;
