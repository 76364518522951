import toastr from "toastr";
import "toastr/build/toastr.min.css";

toastr.options = {
  closeButton: true,
  newestOnTop: true,
  positionClass: "toast-bottom-center",
  preventDuplicates: false,
  onclick: null,
  showDuration: 0,
  hideDuration: 1000,
  timeOut: 5000,
  extendedTimeOut: 1000,
  showEasing: "swing",
  hideEasing: "linear",
  hideMethod: "fadeOut",
};

export const successMsg = (message) => {
  return toastr.success(message);
};

export const infoMsg = (message) => {
  return toastr.info(message);
};

export const warningMsg = (message) => {
  return toastr.warning(message);
};

export const errorMsg = (message) => {
  return toastr.error(message);
};
