// OPAC
export const homepage = "/";

export const login = "/login";
export const forgotPassword = "/forgotpassword";
export const resetPasswordParam = "/resetpassword/:token";
export const ativateAccountParam = "/register/:token";

// Dashboard
export const dashboard = "/dashboard";

// Users
export const superAdminList = "/users/admins";
export const usersList = "/users/list";

// Companies
export const companiesList = "/companies";

// Sites list
export const sitesList = "/site/list";

// Site documents
export const siteDocuments = (siteId, parentId) =>
  `/site/${siteId}/documents${parentId ? `/${parentId}` : ""}`;
export const siteDocumentsId = "/site/:siteId/documents";
export const siteDocumentsParentId = "/site/:siteId/documents/:parentId";

// Site documents
export const siteMembersList = (siteId) => `/site/${siteId}/members`;
export const siteMembersListId = "/site/:siteId/members";

// Site classifications
export const siteClassifications = (siteId) =>
  `/site/${siteId}/classifications`;
export const siteClassificationsId = "/site/:siteId/classifications";
export const siteClassificationsItems = (siteId, classificationId) =>
  `/site/${siteId}/classifications/${classificationId}`;
export const siteClassificationsItemsId =
  "/site/:siteId/classifications/:classificationId";

// Site tags
export const siteTags = (siteId) => `/site/${siteId}/tags`;
export const siteTagsId = "/site/:siteId/tags";

// Site recycle bin
export const siteTrash = (siteId) => `/site/${siteId}/trash`;
export const siteTrashId = "/site/:siteId/trash";

//Profile
export const profile = "/profile";

// Tutorial
export const tutorial = "/tutorial";

// Error 404
export const pageError404 = "*";
