import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import Select from "react-select";
import ValidationMessage from "../../../helpers/ValidationMessage";

import * as Request from "../../../api/Request";
import * as ApiUrl from "../../../api/ApiUrl";
import * as Message from "../../../helpers/Messages";

const CreateSite = ({ onShowModal, setSiteVisible, setAction }) => {
  const { t } = useTranslation("");
  
  const [memberList, setMemberList] = useState([]);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [members, setMembers] = useState([]);
  const [btnLoading, setBtnLoading] = useState(false);
  const [error, setError] = useState([]);

  useEffect(() => getMembersList(), []);

  const onClose = () => {
    setName("");
    setDescription("");
    setMembers([]);
    setSiteVisible(false);
    setError([]);
  };

  const getMembersList = () => {
    Request.getRequest(ApiUrl.membersExpectMe).then((response) => {
      setBtnLoading(false);
      if (response.status === 200) {
        setMemberList(response.data.users);
      }
    });
  };

  const createSite = (e) => {
    e.preventDefault();
    setBtnLoading(true);

    var formData = new FormData();

    formData.append("name", name);
    formData.append("description", description);

    members.forEach((member, i) => {
      formData.append(`users[${i}]`, member.value);
    });

    Request.postRequest(ApiUrl.storeSite, formData).then((response) => {
      setBtnLoading(false);
      if (response.status === 201) {
        Message.successMsg(response.data.success);
        setAction(true);
        onClose();
      } else if (response.status === 422) {
        setError(response.data);
      } else {
        Message.errorMsg(t("error_message"));
      }
    });
  };

  return (
    <Modal isOpen={onShowModal} toggle={(e) => onClose(e)} size="md" centered>
      <ModalHeader
        className="text-dark font-size-15"
        toggle={(e) => onClose(e)}
        tag="span"
      >
        {t("create_site")}
      </ModalHeader>
      <form onSubmit={(e) => createSite(e)} className="text-center">
        <ModalBody>
          <div className="form-block row mb-0">
            <div className="col-12 mb-3">
              <input
                type="text"
                name="name"
                className={"form-input" + (error?.name ? " input-error" : "")}
                onChange={(e) => setName(e.target.value)}
                value={name}
                placeholder={t("create_site_name")}
              />
              {error?.name && <ValidationMessage message={error?.name} />}
            </div>
          </div>
          <div className="form-block row mb-0">
            <div className="col-12 mb-3">
              <textarea
                name="description"
                className={
                  "form-input" + (error?.description ? " input-error" : "")
                }
                onChange={(e) => setDescription(e.target.value)}
                value={description}
                cols="50"
                rows="3"
                placeholder={t("create_site_description")}
              />
              {error?.description && (
                <ValidationMessage message={error?.description} />
              )}
            </div>
          </div>
          <div className="form-block row mb-0">
            <div>
              <Select
                className={
                  "form-react-select" + (error.member ? " input-error" : "")
                }
                isMulti
                classNamePrefix="select_input"
                name="users"
                isClearable={true}
                placeholder={t("create_site_choose")}
                onChange={(e) => (e ? setMembers(e) : setMembers([]))}
                isSearchable={true}
                options={memberList.map((item) => ({
                  value: item.id,
                  label: item.first_name + " " + item.last_name,
                }))}
              />
              {error.member && <ValidationMessage message={error.member[0]} />}
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="col-12 d-flex m-0 p-0">
            <div className="col-6 pe-2">
              {btnLoading ? (
                <button className="button-blue w-100 has-icon" type="button">
                  <i className="icon spinner-icon me-2" />
                  {t("create_site_saving_button")}
                </button>
              ) : (
                <button type="submit" className="button-blue w-100">
                  {t("create_site_save_button")}
                </button>
              )}
            </div>
            <div className="col-6 ps-2">
              <button
                className="button-blue-border w-100"
                type="button"
                onClick={() => onClose()}
              >
                {t("create_site_cancel_button")}
              </button>
            </div>
          </div>
        </ModalFooter>
      </form>
    </Modal>
  );
};
export default CreateSite;
