import axios from "axios";

import * as Message from "../helpers/Messages";
import * as AppConfig from "../helpers/AppConfig";
import * as Path from "../routes/GeneralPaths";

const errorResponse = (error) => {
  if (error.response) {
    if (
      error.response.status === 401 &&
      error.response.data.error === "Unauthenticated"
    ) {
      window.location.href = Path.login;
      AppConfig.deleteAccessToken();
    } else {
      return error.response;
    }
  } else {
    return null;
  }
};

export const getRequest = async (getUrl) => {
  try {
    const response = await axios.get(getUrl, AppConfig.apiConfigParams());
    if (response.data.warning && response.data.warning.length > 0) {
      Message.warningMsg(response.data.warning);
    }
    return response;
  } catch (error) {
    return errorResponse(error);
  }
};

export const getRequestForDflip = async (getUrl) => {
  try {
    const response = await axios.get(getUrl, AppConfig.apiConfigParams());
    if (response.data.warning && response.data.warning.length > 0) {
      Message.warningMsg(response.data.warning);
    }
    return response;
  } catch (error) {
    return errorResponse(error);
  }
};

export const getRequestForDownloadExcel = async (getUrl) => {
  try {
    const response = await axios.get(
      getUrl,
      AppConfig.apiConfigParamsForDownloadExcel()
    );
    if (response.data.warning && response.data.warning.length > 0) {
      Message.warningMsg(response.data.warning);
    }
    return response;
  } catch (error) {
    return errorResponse(error);
  }
};

export const postRequest = async (postUrl, postJson) => {
  try {
    const response = await axios.post(
      postUrl,
      postJson,
      AppConfig.apiConfigParams()
    );
    if (response.data.warning && response.data.warning.length > 0) {
      Message.warningMsg(response.data.warning);
    }
    return response;
  } catch (error) {
    return errorResponse(error);
  }
};
export const postRequestLogin = async (postUrl, postJson) => {
  try {
    const response = await axios.post(
      postUrl,
      postJson,
      AppConfig.apiConfigParamsLogin()
    );
    if (response.data.warning && response.data.warning.length > 0) {
      Message.warningMsg(response.data.warning);
    }
    return response;
  } catch (error) {
    return errorResponse(error);
  }
};

export const postRequestDownload = async (postUrl, postJson) => {
  try {
    const response = await axios.post(
      postUrl,
      postJson,
      AppConfig.apiConfigParamsForDownload()
    );
    if (response.data.warning && response.data.warning.length > 0) {
      Message.warningMsg(response.data.warning);
    }
    return response;
  } catch (error) {
    return errorResponse(error);
  }
};

export const postRequestExport = async (postUrl, postJson) => {
  try {
    const response = await axios.post(
      postUrl,
      postJson,
      AppConfig.apiConfigParamsForExport()
    );
    if (response.data.warning && response.data.warning.length > 0) {
      Message.warningMsg(response.data.warning);
    }
    return response;
  } catch (error) {
    return errorResponse(error);
  }
};

export const patchRequest = async (patchUrl, postJson) => {
  try {
    const response = await axios.patch(
      patchUrl,
      postJson,
      AppConfig.apiConfigParams()
    );
    if (response.data.warning && response.data.warning.length > 0) {
      Message.warningMsg(response.data.warning);
    }
    return response;
  } catch (error) {
    return errorResponse(error);
  }
};

export const patchRequestUrlencoded = async (patchUrl, postJson) => {
  try {
    const response = await axios.patch(
      patchUrl,
      postJson,
      AppConfig.apiConfigParamsUrlencoded()
    );
    if (response.data.warning && response.data.warning.length > 0) {
      Message.warningMsg(response.data.warning);
    }
    return response;
  } catch (error) {
    return errorResponse(error);
  }
};

export const deleteRequest = async (deleteUrl) => {
  try {
    const response = await axios.delete(deleteUrl, AppConfig.apiConfigParams());
    if (response.data.warning && response.data.warning.length > 0) {
      Message.warningMsg(response.data.warning);
    }
    return response;
  } catch (error) {
    return errorResponse(error);
  }
};
