import React, { useEffect } from "react";

const ScrollToTop = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // This code removes the class from the body
  document.querySelector("body").classList.remove("overflow-hidden");

  return <>{props.children}</>;
};

export default ScrollToTop;
