import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import SearchFilter from "../../../../../helpers/SearchFilter";
import ListResponse from "../../../../../helpers/ListResponse";
import Loading from "../../../../../helpers/Loading";
import CustomPagination from "../../../../../helpers/CustomPagination";
import EditItem from "./EditItem";
import AddNewItem from "./AddNewItem";
import { useUserdata } from "../../../../../store/UserData";

import * as Request from "../../../../../api/Request";
import * as ApiUrl from "../../../../../api/ApiUrl";
import * as Message from "../../../../../helpers/Messages";
import * as ConfirmPopup from "../../../../../helpers/ConfirmPopup";

const Items = ({
  showAddItemModal,
  closeItemModal,
  clickedItem,
  setClickedItem,
}) => {
  const { t } = useTranslation();
  const { siteId, classificationId } = useParams();
  const navigate = useNavigate();
  const userData = useUserdata((state) => state.userData);

  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activePage, setActivePage] = useState("");
  const [action, setAction] = useState(false);
  const [pagination, setPagination] = useState([]);
  const [itemId, setItemId] = useState("");
  const [itemModal, setItemModal] = useState(false);

  const setItemVisible = (visible) => {
    setItemModal(visible);
    setItemId("");
  };

  useEffect(() => {
    listItems();
  }, [action, activePage, classificationId]);

  const listItems = () => {
    if (clickedItem) {
      setLoading(true);
      setActivePage("");
      setClickedItem(false);
    }
    let request = ApiUrl.itemsList(classificationId);

    var searchParams = {};

    if (activePage > 1) {
      searchParams = {
        page: activePage,
      };
    }

    request = SearchFilter(searchParams, request);

    Request.getRequest(request).then((response) => {
      if (response.status === 200) {
        setItems(response.data.items.data);
        setLoading(false);
        setAction(false);
        setPagination(response.data.items);
        if (response.data.items.data.length < 1 && activePage > 1) {
          setActivePage(activePage - 1);
          setAction(true);
        }
      } else if (response.status === 403) {
        setAction(false);
        setTimeout(() => {
          navigate(-1);
        }, 2000);
        Message.errorMsg(response.data.error);
      } else if (response.status === 404) {
        setAction(false);
        setTimeout(() => {
          navigate(-1);
        }, 2000);
        Message.errorMsg(response.data.error);
      } else {
        setTimeout(() => {
          navigate(-1);
        }, 2000);
        Message.errorMsg(t("error_message"));
      }
    });
  };

  const deleteItem = (e, id, name) => {
    ConfirmPopup.deletePopup(name).then((response) => {
      if (response.isConfirmed) {
        Request.deleteRequest(ApiUrl.itemDelete(siteId, id)).then(
          (response) => {
            if (response.status === 200) {
              Message.successMsg(response.data.success);
              setAction(true);
            } else if (response.status === 404) {
              Message.errorMsg(response.data.error);
            } else {
              Message.errorMsg(t("error_message"));
            }
          }
        );
      }
    });
  };

  return (
    <>
      <div className="table-list mb-5 table-content">
        <table role="table" className="w-100">
          <thead>
            <tr role="row">
              <th
                role="columnheader"
                className="text-left"
                style={
                  userData.role === "superadmin" || userData.role === "admin"
                    ? { width: "80%" }
                    : { width: "100%" }
                }
              >
                {t("items_list_name")}
              </th>
              {(userData.role === "superadmin" ||
                userData.role === "admin") && (
                <th
                  role="columnheader"
                  className="text-center"
                  style={{ width: "20%" }}
                >
                  {t("items_list_action")}
                </th>
              )}
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td
                  className="text-center"
                  colSpan={
                    userData.role === "superadmin" || userData.role === "admin"
                      ? "2"
                      : "1"
                  }
                >
                  <Loading />
                </td>
              </tr>
            ) : (
              items.map((item) => (
                <tr key={item.id} role="row">
                  <td role="cell" data-label={t("items_list_name")}>
                    {item.name}
                  </td>
                  {(userData.role === "superadmin" ||
                    userData.role === "admin") && (
                    <td
                      role="cell"
                      data-label={t("items_list_action")}
                      className="text-center"
                    >
                      <div className="row justify-content-center mr-auto ml-auto action-icons">
                        <div className="edit">
                          <button
                            className="button-unstyled-icon"
                            onClick={(e) => {
                              setItemModal(true);
                              setItemId(item.id);
                            }}
                          >
                            <i className="icon edit-icon" />
                          </button>
                          {itemModal && itemId === item.id && (
                            <EditItem
                              onShowModal={itemModal}
                              setItemVisible={setItemVisible}
                              setModified={setAction}
                              itemId={item.id}
                              itemName={item.name}
                            />
                          )}
                        </div>
                        <div className="delete">
                          <button
                            type="submit"
                            className="button-unstyled-icon"
                            onClick={(e) => deleteItem(e, item.id, item.name)}
                          >
                            <i className="icon delete-icon" />
                          </button>
                        </div>
                      </div>
                    </td>
                  )}
                </tr>
              ))
            )}
          </tbody>
        </table>
        <ListResponse
          totalItems={pagination && pagination.total}
          loading={loading}
          searched={false}
        />
        {!loading && (
          <CustomPagination
            data={pagination}
            setActivePage={(e) => setActivePage(e)}
          />
        )}
      </div>
      {showAddItemModal && (
        <AddNewItem
          onShowModal={showAddItemModal}
          setItemVisible={(e) => closeItemModal(e)}
          setModified={(e) => setAction(e)}
        />
      )}
    </>
  );
};

export default Items;
