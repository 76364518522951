import { useState } from "react";
import { useTranslation } from "react-i18next";

import * as Request from "../../api/Request";
import * as ApiUrl from "../../api/ApiUrl";
import * as Message from "../../helpers/Messages";

import ValidationMessage from "../../helpers/ValidationMessage";

const ForgotPassword = () => {
  const { t } = useTranslation("");

  const [email, setEmail] = useState("");
  const [errorList, setErrorList] = useState([]);
  const [btnLoading, setBtnLoading] = useState(false);

  const forgotPassword = (e) => {
    e.preventDefault();
    setBtnLoading(true);

    const formData = {
      email: email,
    };

    Request.postRequest(ApiUrl.resetLink, formData).then((response) => {
      setBtnLoading(false);

      if (response.status === 200) {
        setEmail("");
        setErrorList([]);
        Message.successMsg(response.data.success);
      } else if (response.status === 404 || response.status === 422) {
        setErrorList(response.data);
      }
    });
  };

  return (
    <div className="form-logins">
      <div className="login_bg">
        <p className="login_note">{t("forgot_password_text")}</p>
        <form className="w-100" onSubmit={(e) => forgotPassword(e)}>
          <div className="form-group">
            <input
              type="text"
              name="email"
              placeholder={t("forgot_password_placeholder")}
              value={email}
              className={
                "form-input" +
                (errorList.email || errorList.error ? " input-error" : "")
              }
              onChange={(e) => setEmail(e.target.value)}
            />
            <i className="icon lock-icon"></i>
            {errorList.email && (
              <ValidationMessage message={errorList.email[0]} />
            )}
            {errorList.error && <ValidationMessage message={errorList.error} />}
          </div>
          {btnLoading ? (
            <button type="button" className="button-blue has-icon w-100 mt-2">
              <i className="spinner-icon" />
              {t("forgot_password_btn_loading")}
            </button>
          ) : (
            <button type="submit" className="button-blue w-100 mt-2">
              {t("forgot_password_btn")}
            </button>
          )}
        </form>
      </div>
    </div>
  );
};

export default ForgotPassword;
