import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";

import Loading from "../../../../../helpers/Loading";
import EditFolder from "./EditFolder";
import ListResponse from "../../../../../helpers/ListResponse";

import * as Request from "../../../../../api/Request";
import * as ApiUrl from "../../../../../api/ApiUrl";
import * as Message from "../../../../../helpers/Messages";
import * as Path from "../../../../../routes/GeneralPaths";
import * as ConfirmPopup from "../../../../../helpers/ConfirmPopup";
import { useUserdata } from "../../../../../store/UserData";

const FoldersList = ({ folders, loading, setAction, folderName }) => {
  const { t } = useTranslation();
  const { siteId, parentId } = useParams();
  const navigate = useNavigate();
  const userData = useUserdata((state) => state.userData);

  const [editFolderModal, setEditFolderModal] = useState(false);
  const [folderId, setFolderId] = useState("");

  const setFolderVisible = (visible) => {
    setEditFolderModal(visible);
    setFolderId("");
  };

  //   Delete folder
  const deleteFolder = (e, id, name) => {
    ConfirmPopup.deleteFolderPopup(name).then((response) => {
      if (response.isConfirmed) {
        Request.deleteRequest(ApiUrl.deleteFolder(siteId) + id).then(
          (response) => {
            if (response.status === 200) {
              Message.successMsg(response.data.success);
              setAction(true);
            } else if (response.status === 404) {
              Message.errorMsg(response.data.error);
            } else {
              Message.errorMsg(t("error_message"));
            }
          }
        );
      }
    });
  };

  return (
    <>
      <div className="folders-title">
        {t("site_folders_label")}
        {folderName ? (
          <>
            {` >`} <span> {folderName.name}</span>
          </>
        ) : (
          ""
        )}
      </div>
      {parentId ? (
        <button
          className="button-gray-blue back-button px-3 mb-3"
          onClick={() => navigate(-1)}
        >
          <i className="icon left-arrow-icon" />
        </button>
      ) : (
        ""
      )}

      {loading ? (
        <div className="d-flex justify-content-center mb-5">
          <Loading />
        </div>
      ) : folders.length > 0 ? (
        <div className="folders-container mb-5">
          {folders.map((folder) => (
            <div key={folder.id} className="folder">
              <i
                className="icon folder-icon"
                onClick={() => navigate(Path.siteDocuments(siteId, folder.id))}
              />
              <span
                className="folder-name"
                onClick={() => navigate(Path.siteDocuments(siteId, folder.id))}
              >
                {folder.name}
              </span>
              {userData.role_id !== folder.owner_id && (
                <UncontrolledDropdown>
                  <DropdownToggle
                    className="icon dots-vertical-triple-icon"
                    tag="i"
                  />
                  <DropdownMenu className="dropdown-white dropdown-menu-start">
                    <DropdownItem
                      className="notify-item"
                      onClick={() => {
                        setEditFolderModal(true);
                        setFolderId(folder.id);
                      }}
                    >
                      {t("site_folders_edit_folder")}
                    </DropdownItem>
                    <DropdownItem
                      className="notify-item"
                      onClick={(e) => deleteFolder(e, folder.id, folder.name)}
                    >
                      {t("site_folders_delete_folder")}
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              )}

              {editFolderModal && folderId === folder.id && (
                <EditFolder
                  onShowModal={editFolderModal}
                  setFolderVisible={() => setFolderVisible()}
                  setModified={(e) => setAction(e)}
                  folderId={folder.id}
                  folderName={folder.name}
                />
              )}
            </div>
          ))}
        </div>
      ) : (
        <div className="d-flex mb-5">
          <ListResponse totalItems={0} />
        </div>
      )}
      {}
    </>
  );
};

export default FoldersList;
