import React, { useEffect } from "react";
import AppRouter from "./routes/AppRouter";
import "./assets/scss/main.scss";

import faviconDark from "../src/assets/images/favicons/favicon-dark.png";
import faviconLight from "../src/assets/images/favicons/favicon-light.png";

function App() {
  useEffect(() => {
    const favicon = document.getElementById("favicon");
    const darkModeQuery = window.matchMedia("(prefers-color-scheme: dark)");
    const handleChangeColorScheme = () => {
      if (darkModeQuery.matches) {
        favicon.href = faviconLight;
      } else {
        favicon.href = faviconDark;
      }
    };

    handleChangeColorScheme();
    darkModeQuery.addEventListener("change", handleChangeColorScheme);
    return () =>
      darkModeQuery.removeEventListener("change", handleChangeColorScheme);
  }, []);

  return (
    <div className="wrapper">
      <AppRouter />
    </div>
  );
}

export default App;
