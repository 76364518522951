import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import ListResponse from "../../../helpers/ListResponse";
import Loading from "../../../helpers/Loading";
import CreateSite from "./CreateSite";

import * as Path from "../../../routes/GeneralPaths";
import * as Request from "../../../api/Request";
import * as ApiUrl from "../../../api/ApiUrl";
import * as ConfirmPopup from "../../../helpers/ConfirmPopup";
import * as Message from "../../../helpers/Messages";

import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import CheckRelationToSite from "../../../helpers/CheckRelationToSite";
import { TooltipDesc } from "../../../helpers/Tooltip";
import EditSite from "./EditSite";
import InfiniteScroll from "react-infinite-scroll-component";

const Sites = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [listOfAllSites, setListOfAllSites] = useState([]);
  const [itemsNr, setItemsNr] = useState(0);
  const [listOfSites, setListOfSites] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingInfinite, setLoadingInfinite] = useState(false);

  const [action, setAction] = useState(false);

  // Add site modal
  const [addSiteModal, setAddSiteModal] = useState(false);
  const setAddSiteVisible = (visible) => {
    setAddSiteModal(visible);
  };

  // Edit site modal
  const [editSiteModal, setEditSiteModal] = useState(false);
  const [editSiteId, setEditSiteId] = useState("");
  const setEditSiteVisible = (visible) => {
    setEditSiteModal(visible);
    setEditSiteId("");
  };

  useEffect(() => {
    listSites();
  }, [action]);

  useEffect(() => {
    fetchMoreData();
  }, [listOfAllSites]);

  const listSites = () => {
    Request.getRequest(ApiUrl.listOfSites).then((response) => {
      if (response.status === 200) {
        setListOfAllSites(response.data.sites);
        setLoading(false);
        setAction(false);
      } else {
        setLoading(true);
      }
    });
  };

  const fetchMoreData = () => {
    if (itemsNr <= 12) {
      setListOfSites(listOfAllSites.slice(0, itemsNr));
      setItemsNr(itemsNr + 12);
    } else {
      setLoadingInfinite(true);
      setTimeout(() => {
        setListOfSites(listOfAllSites.slice(0, itemsNr));
        setItemsNr(itemsNr + 12);
        setLoadingInfinite(false);
      }, 1500);
    }
  };

  const deleteSite = (id, name) => {
    ConfirmPopup.deleteSitePopup(name).then((response) => {
      if (response.isConfirmed) {
        Request.deleteRequest(ApiUrl.deleteSite + id).then((response) => {
          if (response.status === 200) {
            setAction(true);
            Message.successMsg(response.data.success);
          } else if (response.status === 404) {
            Message.errorMsg(response.data.error);
          } else {
            Message.errorMsg(t("error_message"));
          }
        });
      }
    });
  };

  return (
    <>
      <div id="list-of-sites">
        <p className="partial-title mb-5">{t("list_of_sites")}</p>
        <div className="input-items text-end mb-5">
          <button
            type="button"
            className="button-blue has-icon"
            onClick={() => setAddSiteModal(true)}
          >
            {t("add_new_site")}
          </button>
          {addSiteModal && (
            <CreateSite
              onShowModal={addSiteModal}
              setSiteVisible={(e) => setAddSiteVisible(e)}
              setAction={(e) => setAction(e)}
            />
          )}
        </div>
        {loading ? (
          <div className="sites-list">
            <div className="text-center m-auto">
              <Loading />
            </div>
          </div>
        ) : (
          <InfiniteScroll
            style={{ overflow: "hidden" }}
            dataLength={listOfSites.length}
            next={fetchMoreData}
            hasMore={true}
            loader={
              loadingInfinite && (
                <div className="text-center mt-4 m-auto">
                  <Loading />
                </div>
              )
            }
          >
            <div className="sites-list">
              {listOfSites.map((site, index) => (
                <div key={index} className="site">
                  <div className="site-info-block">
                    <div className="site-data">
                      <span
                        className={
                          site.relation_to_site === "owner"
                            ? "green-tag"
                            : site.relation_to_site === "member"
                            ? "red-tag"
                            : "blue-tag"
                        }
                      >
                        {CheckRelationToSite(site.relation_to_site)}
                      </span>
                      <div className="item-row">
                        <span
                          className="item-title"
                          onClick={() => navigate(Path.siteDocuments(site.id))}
                        >
                          {site.name}
                        </span>
                        <span className="item-value">
                          {site.description
                            ? TooltipDesc(index, site.description)
                            : ""}
                        </span>
                      </div>
                    </div>
                    <div className="site-actions">
                      <UncontrolledDropdown>
                        <DropdownToggle
                          className="icon dots-horizontal-triple-icon"
                          tag="i"
                        />
                        <DropdownMenu className="dropdown-white dropdown-menu-start">
                          <DropdownItem
                            className="notify-item"
                            onClick={() =>
                              navigate(Path.siteDocuments(site.id))
                            }
                          >
                            {t("site_list_view_site")}
                          </DropdownItem>
                          {site.relation_to_site === "owner" && (
                            <>
                              <DropdownItem
                                className="notify-item"
                                onClick={() => {
                                  setEditSiteModal(true);
                                  setEditSiteId(site.id);
                                }}
                              >
                                {t("site_list_edit_site")}
                              </DropdownItem>
                              <DropdownItem
                                className="notify-item"
                                onClick={(e) => deleteSite(site.id, site.name)}
                              >
                                {t("site_list_delete_site")}
                              </DropdownItem>
                            </>
                          )}
                        </DropdownMenu>
                      </UncontrolledDropdown>
                      {editSiteId === site.id && editSiteModal && (
                        <EditSite
                          onShowModal={editSiteModal}
                          setSiteVisible={(e) => setEditSiteVisible(e)}
                          setAction={(e) => setAction(e)}
                          siteId={editSiteId}
                        />
                      )}
                    </div>
                  </div>
                  <div className="site-quick-links">
                    <span>{t("site_list_quick_link")}</span>
                    <div className="quick-links">
                      <Link to={Path.siteMembersList(site.id)}>
                        {t("site_list_members")} <span>{site.members}</span>
                      </Link>
                      <Link to={Path.siteClassifications(site.id)}>
                        {t("site_list_classifications")}
                        <span>{site.classifications}</span>
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </InfiniteScroll>
        )}
        <ListResponse
          totalItems={
            !loading && listOfAllSites.length < 1 && listOfAllSites.length
          }
          loading={false}
          searched={false}
        />
      </div>
    </>
  );
};

export default Sites;
