import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "../../../../helpers/Loading";

import * as Request from "../../../../api/Request";
import * as ApiUrl from "../../../../api/ApiUrl";
import * as Message from "../../../../helpers/Messages";

const Members = () => {
  const { t } = useTranslation("");
  const { siteId } = useParams();
  const navigate = useNavigate();

  const [siteOwner, setSiteOwner] = useState({});

  const [members, setMembers] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    siteMembersList();
  }, []);

  const siteMembersList = () => {
    Request.getRequest(ApiUrl.siteMembers(siteId)).then((response) => {
      if (response.status === 201) {
        setMembers(response.data.members);
        setSiteOwner(response.data.owner);
        setLoading(false);
      } else if (response.status === 404) {
        setTimeout(() => {
          navigate(-1);
        }, 2000);
        Message.errorMsg(response.data.error);
      } else if (response.status === 403) {
        setTimeout(() => {
          navigate(-1);
        }, 2000);
        Message.errorMsg(response.data.error);
      } else {
        Message.errorMsg(t("error_message"));
        setTimeout(() => {
          navigate(-1);
        }, 2000);
      }
    });
  };

  return (
    <>
      <p className="partial-title mb-5">{t("site_members_title")}</p>
      <div className="table-list mb-5">
        <table role="table" className="w-100">
          <thead>
            <tr role="row">
              <th
                role="columnheader"
                className="text-left"
                style={{ width: "25%" }}
              >
                {t("site_members_name")}
              </th>
              <th
                role="columnheader"
                className="text-center"
                style={{ width: "25%" }}
              >
                {t("site_members_last_name")}
              </th>
              <th
                role="columnheader"
                className="text-center"
                style={{ width: "25%" }}
              >
                {t("site_members_email")}
              </th>
              <th
                role="columnheader"
                className="text-center"
                style={{ width: "25%" }}
              >
                {t("site_members_phone")}
              </th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td className="text-center" colSpan="4">
                  <Loading />
                </td>
              </tr>
            ) : (
              <>
                <tr role="row" style={{ background: "#f4f5f9" }}>
                  <td role="cell" data-label={t("site_members_name")}>
                    {siteOwner.first_name}
                  </td>
                  <td
                    role="cell"
                    data-label={t("site_members_last_name")}
                    className="text-center"
                  >
                    {siteOwner.last_name}
                  </td>
                  <td
                    role="cell"
                    data-label={t("site_members_email")}
                    className="text-center"
                  >
                    {siteOwner.email}
                  </td>
                  <td
                    role="cell"
                    data-label={t("site_members_phone")}
                    className="text-center"
                  >
                    {siteOwner.phone}
                  </td>
                </tr>
                {members.map((member) => (
                  <tr role="row" key={member.id}>
                    <td role="cell" data-label={t("site_members_name")}>
                      {member.first_name}
                    </td>
                    <td
                      role="cell"
                      data-label={t("site_members_last_name")}
                      className="text-center"
                    >
                      {member.last_name}
                    </td>
                    <td
                      role="cell"
                      data-label={t("site_members_email")}
                      className="text-center"
                    >
                      {member.email}
                    </td>
                    <td
                      role="cell"
                      data-label={t("site_members_phone")}
                      className="text-center"
                    >
                      {member.phone}
                    </td>
                  </tr>
                ))}
              </>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default Members;
