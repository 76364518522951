import Footer from "./Footer";
import Header from "./Header";

const MainStructure = (data) => {
  return (
    <>
      <Header />
      <div className="main-content-container">
        <div className="page-content">{data.children}</div>
      </div>
      <Footer />
    </>
  );
};

export default MainStructure;
