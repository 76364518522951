import React from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import Sidebar from "../../../../components/layouts/sidebar/Sidebar";
import Trash from "../../../../components/panel/sites/trash/Trash";

const TrashView = () => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{t("site_recycle_bin_tab_title")}</title>
      </Helmet>
      <Sidebar siteMenu={true}>
        <Trash />
      </Sidebar>
    </>
  );
};

export default TrashView;
