import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

import * as ApiUrl from "../../../../../api/ApiUrl";
import * as Request from "../../../../../api/Request";
import * as Message from "../../../../../helpers/Messages";
import moment from "moment";

const ViewFile = ({ onShowModal, setFileVisible, fileId }) => {
  const { t } = useTranslation("");

  const [document, setDocument] = useState({});

  const onClose = () => {
    setFileVisible(false);
  };

  const getFileDetails = () => {
    Request.getRequest(ApiUrl.getFileDetails(fileId)).then((response) => {
      if (response.status === 200) {
        setDocument(response.data.document);
      } else if (response.status === 403) {
        Message.errorMsg(response.data.error);
        onClose();
      } else if (response.status === 404) {
        Message.errorMsg(response.data.error);
        onClose();
      } else {
        Message.errorMsg(t("error_message"));
        onClose();
      }
    });
  };

  useEffect(() => {
    getFileDetails();
  }, []);

  return (
    Object.keys(document).length > 0 && (
      <Modal isOpen={onShowModal} toggle={(e) => onClose(e)} size="md" centered>
        <ModalHeader
          className="text-dark font-size-15"
          toggle={(e) => onClose(e)}
          tag="span"
        >
          {t("view_document_popup_title")}
        </ModalHeader>
        <ModalBody className="view-file">
          <div>
            <span className="title">{t("view_document_name")} : </span>
            <span className="value">{document.name}</span>
          </div>
          <div>
            <span className="title">{t("view_document_path")} : </span>
            <span className="value">{document.path}</span>
          </div>
          <div>
            <span className="title">{t("view_document_uploaded_at")} : </span>
            <span className="value">
              {moment(document.created_at).format("DD MMMM, YYYY")}
            </span>
          </div>

          {document.items.map((item) => (
            <div key={item.id}>
              <span className="title">{item.classification.name} : </span>
              <span className="value">{item.name}</span>
            </div>
          ))}
          {document.tags.length > 0 && (
            <div>
              <span className="title">{t("view_document_tags")}</span>
              <div className="tags-container">
                {document.tags.map((tag) => (
                  <span key={tag.id} className="tag">
                    {tag.name}
                  </span>
                ))}
              </div>
            </div>
          )}
        </ModalBody>
        <ModalFooter>
          <button
            className="button-blue-border"
            type="button"
            onClick={() => onClose()}
          >
            {t("view_document_cancel_button")}
          </button>
        </ModalFooter>
      </Modal>
    )
  );
};

export default ViewFile;
