import React from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import Sidebar from "../../../../components/layouts/sidebar/Sidebar";
import Tags from "../../../../components/panel/sites/tags/Tags";

const TagsView = () => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{t("site_tags_tab_title")}</title>
      </Helmet>
      <Sidebar siteMenu={true}>
        <Tags />
      </Sidebar>
    </>
  );
};

export default TagsView;
