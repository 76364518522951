import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import ValidationMessage from "../../../../helpers/ValidationMessage";

import * as Request from "../../../../api/Request";
import * as ApiUrl from "../../../../api/ApiUrl";
import * as Message from "../../../../helpers/Messages";

const EditClassification = ({
  onShowModal,
  setClassificationVisible,
  setModified,
  classificationId,
  classificationName,
}) => {
  const { t } = useTranslation("");
  const { siteId } = useParams();

  const [name, setName] = useState(classificationName);
  const [btnLoading, setBtnLoading] = useState(false);
  const [validations, setValidations] = useState({});

  //The function that closes the modal
  const onClose = () => {
    setClassificationVisible(false);
    setValidations({});
  };

  const updateClassification = (e) => {
    e.preventDefault();

    var data = {
      name: name,
      site_id: siteId,
    };
    setBtnLoading(true);

    Request.patchRequest(
      ApiUrl.updateClassification(classificationId),
      data
    ).then((response) => {
      setBtnLoading(false);
      if (response.status === 200) {
        onClose();
        setModified(true);
        Message.successMsg(response.data.success);
      } else if (response.status === 422) {
        setValidations(response.data);
      }
    });
  };

  return (
    <Modal isOpen={onShowModal} toggle={(e) => onClose(e)} size="md" centered>
      <ModalHeader
        className="text-dark font-size-15"
        toggle={(e) => onClose(e)}
        tag="span"
      >
        {t("edit_classification_popup_title")}
      </ModalHeader>
      <form onSubmit={(e) => updateClassification(e)} className="text-center">
        <ModalBody>
          <div className="form-block row mb-0">
            <div className="col-12 mb-3">
              <input
                type="text"
                name="name"
                className={
                  "form-input" + (validations?.name ? " input-error" : "")
                }
                onChange={(e) => setName(e.target.value)}
                value={name}
                placeholder={t("edit_classification_popup_placeholder")}
              />
              {validations?.name && (
                <ValidationMessage message={validations?.name} />
              )}
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="col-12 d-flex m-0 p-0">
            <div className="col-6 pe-2">
              {btnLoading ? (
                <button className="button-blue w-100 has-icon" type="button">
                  <i className="icon spinner-icon me-2" />
                  {t("edit_classification_editing_button")}
                </button>
              ) : (
                <button type="submit" className="button-blue w-100">
                  {t("edit_classification_edit_button")}
                </button>
              )}
            </div>
            <div className="col-6 ps-2">
              <button
                className="button-blue-border w-100"
                type="button"
                onClick={() => onClose()}
              >
                {t("edit_classification_cancel_button")}
              </button>
            </div>
          </div>
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default EditClassification;
