import { useState } from "react";

import * as AppConfig from "../../../helpers/AppConfig";
import * as Request from "../../../api/Request";
import * as ApiUrl from "../../../api/ApiUrl";
import * as Path from "../../../routes/GeneralPaths";

import { useUserdata } from "../../../store/UserData";
import { Link } from "react-router-dom";
import { map } from "lodash";

//i18n
import i18n from "../../../i18n/i18n";
import languages from "../../../i18n/Languages";
import { useTranslation } from "react-i18next";

import al from "../../../assets/images/flags/al.jpg";
import us from "../../../assets/images/flags/us.jpg";
import LogoWhite from "../../../assets/images/logo/logo-white.png";

const Navbar = () => {
  const userData = useUserdata((state) => state.userData);
  const { t } = useTranslation("");
  const [mobileMenu, setMobileMenu] = useState(false);

  //Close menu and scroll into section
  function closeMenu() {
    document.querySelector(".mobilemenu-wrapper ").classList.remove("active");
    document.querySelector(".mobilemenu-wrapper ").classList.add("loggedin");
    document.querySelector(".overlay ").classList.remove("open");
    document.body.classList.remove("overflow-hidden");
  }

  //Scroll into
  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    const yOffset = -10;
    const y =
      element.getBoundingClientRect().top + window.pageYOffset + yOffset;
    window.scrollTo({ top: y, behavior: "smooth" });
  };

  const changeLanguageAction = (lang) => {
    //set language as i18n
    i18n.changeLanguage(lang);
    localStorage.setItem("i18nextLng", lang);
    window.location.reload();
  };

  const showHideMenu = () => {
    if (!mobileMenu) {
      setMobileMenu(!mobileMenu);
    } else if (mobileMenu) {
      setMobileMenu(!mobileMenu);
    }
  };

  const logout = () => {
    Request.postRequest(ApiUrl.logout).then((response) => {
      if (response.status === 200) {
        AppConfig.deleteAccessToken();
        window.location.href = Path.homepage;
      }
    });
  };

  const checkRole = () => {
    switch (userData.role) {
      case "superadmin":
        return {
          panelPath: Path.superAdminList,
          passwordPath: Path.profile,
        };
      case "admin":
        return {
          panelPath: Path.usersList,
          passwordPath: Path.profile,
        };
      case "member":
        return {
          panelPath: Path.sitesList,
          passwordPath: Path.profile,
        };
      default:
        return {
          panelPath: "",
          passwordPath: "",
        };
    }
  };

  return (
    <nav className="main-navbar databank-navbar position-relative" id="home">
      <div className="logo">
        <Link to={Path.homepage}>
          <img src={LogoWhite} alt="Logo" height="50px" />
        </Link>
      </div>
      <div className="navbar-menu">
        <ul>
          <li>
            <Link to="/" onClick={() => scrollToSection("home")}>
              {t("navbar_homepage_home")}
            </Link>
          </li>
          <li>
            <Link onClick={() => scrollToSection("features")}>
              {t("navbar_homepage_features")}
            </Link>
          </li>
          <li>
            <Link onClick={() => scrollToSection("contactForm")}>
              {t("navbar_homepage_contact")}
            </Link>
          </li>
          <li>
            <div className="btn-group">
              <button
                type="button"
                className="button-unstyled"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <img
                  src={AppConfig.language() === "al" ? al : us}
                  alt="Flag"
                  className="mb-1"
                  height="16"
                  width="24"
                />
              </button>
              <ul className="dropdown-menu dropdown-menu-end">
                {map(Object.keys(languages), (key, index) => (
                  <li key={index} onClick={() => changeLanguageAction(key)}>
                    <div className="notify-item none">
                      <img
                        src={key === "al" ? al : us}
                        alt="Flag"
                        className="me-1"
                        height="12"
                        width="20"
                      />
                      <span className="align-middle">
                        {key === "al" ? t("albanian") : t("english")}
                      </span>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </li>
          {!AppConfig.isLogged() ? (
            <li>
              <Link to={Path.login} className="button-white-bordered">
                {t("navbar_homepage_login")}
              </Link>
            </li>
          ) : (
            <>
              <div className="btn-group">
                <button
                  type="button"
                  className="button-white-bordered has-icon"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <i className="icon user-icon" />
                </button>
                <ul className="dropdown-menu dropdown-menu-end">
                  <li>
                    <Link to={checkRole().panelPath}>
                      <i className="icon settings-icon me-1" />
                      {t("navbar_panel")}
                    </Link>
                  </li>
                  <li>
                    <Link to={checkRole().passwordPath}>
                      <i className="icon password-icon me-1" />
                      {t("navbar_change_password")}
                    </Link>
                  </li>
                  <li onClick={() => logout()}>
                    <span>
                      <i className="icon logout-icon me-1" />
                      {t("navbar_logout")}
                    </span>
                  </li>
                </ul>
              </div>
            </>
          )}
        </ul>
      </div>

      <li className="navbar-menu-mobile">
        <div className="btn-group">
          <button
            type="button"
            className="button-unstyled me-3"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <img
              src={AppConfig.language() === "al" ? al : us}
              alt="Flag"
              className="mb-1"
              height="16"
              width="24"
            />
          </button>
          <ul className="dropdown-menu dropdown-menu-end">
            {map(Object.keys(languages), (key, index) => (
              <li key={index} onClick={() => changeLanguageAction(key)}>
                <div className="notify-item none">
                  <img
                    src={key === "al" ? al : us}
                    alt="Flag"
                    className="me-1"
                    height="12"
                    width="20"
                  />
                  <span className="align-middle">
                    {key === "al" ? t("albanian") : t("english")}
                  </span>
                </div>
              </li>
            ))}
          </ul>
        </div>
        <div
          className={`mobilemenu-wrapper ${mobileMenu ? "active" : ""} ${
            AppConfig.isLogged() ? "loggedin" : ""
          }`}
          onClick={() => showHideMenu()}
        >
          <span className="bars"></span>
          <span className="bars"></span>
          <span className="bars"></span>
        </div>
        <div className={"overlay" + (mobileMenu ? " open" : "")}>
          <div className="menu-div">
            <ul>
              <li className="main-li">
                {!AppConfig.isLogged() ? (
                  <Link to={Path.login} className="button-white-rounded">
                    {t("navbar_homepage_login")}
                  </Link>
                ) : (
                  <>
                    <div className="logged-in">
                      <ul className="user-actions">
                        <li>
                          <Link to={checkRole().panelPath}>
                            <i className="icon settings-icon me-1" />
                            {t("navbar_panel")}
                          </Link>
                        </li>
                        <li>
                          <Link to={checkRole().passwordPath}>
                            <i className="icon password-icon me-1" />
                            {t("navbar_change_password")}
                          </Link>
                        </li>
                        <li>
                          <button
                            type="button"
                            className="button-white-rounded"
                            onClick={() => logout()}
                          >
                            <i className="icon logout-icon me-1" />
                            {t("navbar_logout")}
                          </button>
                        </li>
                      </ul>
                    </div>
                  </>
                )}
              </li>
            </ul>
            <ul className="menu-mob">
              <li>
                <Link
                  to={""}
                  onClick={() => {
                    scrollToSection("home");
                    closeMenu();
                  }}
                >
                  {t("navbar_homepage_home")}
                </Link>
              </li>
              <li>
                <Link
                  to={""}
                  onClick={() => {
                    scrollToSection("features");
                    closeMenu();
                  }}
                >
                  {t("navbar_homepage_features")}
                </Link>
              </li>
              <li>
                <Link
                  to={""}
                  onClick={() => {
                    scrollToSection("contactForm");
                    closeMenu();
                  }}
                >
                  {t("navbar_homepage_contact")}
                </Link>
              </li>
              {}
            </ul>
          </div>
        </div>
      </li>
    </nav>
  );
};

export default Navbar;
