import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import SearchFilter from "../../../../helpers/SearchFilter";
import FoldersList from "./folders/FoldersList";
import FilesList from "./files/FilesList";
import UploadFile from "./files/UploadFile";
import CreateNewFolder from "./folders/CreateNewFolder";

import * as Request from "../../../../api/Request";
import * as ApiUrl from "../../../../api/ApiUrl";
import * as Message from "../../../../helpers/Messages";

const SiteFiles = () => {
  const { t } = useTranslation("");
  const { siteId, parentId } = useParams();
  const navigate = useNavigate();

  const [action, setAction] = useState(false);

  const [folderName, setFolderName] = useState(null);

  const [searchClassifications, setSearchClassifications] = useState({});
  const [searchTags, setSearchTags] = useState([]);
  const [searchName, setSearchName] = useState("");
  const [searchBtnLoading, setSearchBtnLoading] = useState(false);

  // Upload file modal
  const [uploadFileModal, setUploadFileModal] = useState(false);
  const setUploadFileVisible = (visible) => {
    setUploadFileModal(visible);
  };

  // Create folder modal
  const [createFolderModal, setCreateFolderModal] = useState(false);
  const setFolderVisible = (visible) => {
    setCreateFolderModal(visible);
  };

  // List of site files and folders
  const [loading, setLoading] = useState(true);
  const [siteFolders, setSiteFolders] = useState([]);
  const [siteFiles, setSiteFiles] = useState([]);
  const [activePage, setActivePage] = useState("");

  const siteFilesList = () => {
    let request = ApiUrl.documentFolders(siteId);

    var searchParams = {};

    if (activePage > 1) {
      searchParams.page = activePage;
    }

    for (const [index, key] of Object.keys(searchClassifications).entries()) {
      const value = searchClassifications[key];
      if (value) {
        searchParams[`items[${index}]`] = value;
      }
      setActivePage("");
    }

    if (searchTags.length) {
      searchTags.map(
        (tag, index) => (searchParams[`tags[${index}]`] = tag.value)
      );
      setActivePage("");
    }

    if (searchName.length) {
      searchParams.name = searchName;
      setActivePage("");
    }

    if (parentId) {
      searchParams.parent_folder_id = parentId;
      setActivePage("");
    }

    request = SearchFilter(searchParams, request);

    Request.getRequest(request).then((response) => {
      if (response.status === 200) {
        setSearchBtnLoading(false);
        setSiteFolders(response.data.folders);
        setSiteFiles(response.data.documents);
        setLoading(false);
        setAction(false);
        setFolderName(response.data.folder_name);
        if (response.data.documents.data.length < 1 && activePage > 1) {
          setActivePage(activePage - 1);
          setAction(true);
        }
      } else if (response.status === 403) {
        setTimeout(() => {
          navigate(-1);
        }, 2000);
        Message.errorMsg(response.data.error);
      } else if (response.status === 404) {
        setTimeout(() => {
          navigate(-1);
        }, 2000);
        Message.errorMsg(response.data.error);
      } else {
        setTimeout(() => {
          navigate(-1);
        }, 2000);
        Message.errorMsg(t("error_message"));
      }
    });
  };

  useEffect(() => {
    siteFilesList();
  }, [
    action,
    activePage,
    parentId,
    searchClassifications,
    searchTags,
    searchName,
    searchBtnLoading,
  ]);

  useEffect(() => {
    setLoading(true);
  }, [parentId]);

  return (
    <>
      <p className="partial-title mb-5">{t("site_documents_title")}</p>
      <div className="add-item p-4 mb-5 d-flex flex-wrap gap-3">
        <button
          type="button"
          className="button-blue"
          onClick={() => setCreateFolderModal(true)}
        >
          {t("site_document_create_folder")}
        </button>
        {createFolderModal && (
          <CreateNewFolder
            onShowModal={createFolderModal}
            setFolderVisible={(e) => setFolderVisible(e)}
            setAction={(e) => setAction(e)}
          />
        )}
        <button
          type="button"
          className="button-blue-border has-icon"
          onClick={() => setUploadFileModal(true)}
        >
          {t("site_document_upload_document")}
        </button>
        {uploadFileModal && (
          <UploadFile
            onShowModal={uploadFileModal}
            setFileVisible={(e) => setUploadFileVisible(e)}
            setAction={(e) => setAction(e)}
          />
        )}
      </div>
      <FoldersList
        folders={siteFolders}
        loading={loading}
        setAction={(e) => setAction(e)}
        folderName={folderName}
      />
      <FilesList
        files={siteFiles}
        loading={loading}
        btnLoading={searchBtnLoading}
        setActivePage={(e) => setActivePage(e)}
        setAction={(e) => setAction(e)}
        setSelectedClassifications={(e) => setSearchClassifications(e)}
        setSelectedTags={(e) => setSearchTags(e)}
        setSelectedName={(e) => setSearchName(e)}
        setBtnLoading={(e) => setSearchBtnLoading(e)}
      />
    </>
  );
};

export default SiteFiles;
