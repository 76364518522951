import {  useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { privateRoutes } from "../../routes/Routes";

import * as Path from "../../routes/GeneralPaths";
import * as Request from "../../api/Request";
import * as ApiUrl from "../../api/ApiUrl";
import * as AppConfig from "../../helpers/AppConfig";

import ValidationMessage from "../../helpers/ValidationMessage";

const Login = () => {
  const { t } = useTranslation("");

  const [userEmail, setUserEmail] = useState("");
  const [userPassword, setUserPassword] = useState("");
  const [userRemember, setUserRemember] = useState("0");
  const [userShowPassword, setUserShowPassword] = useState(false);
  const [userErrorList, setUserErrorList] = useState([]);
  const [userShowBtnLoading, setUserShowBtnLoading] = useState(false);

  const userLogin = (e) => {
    e.preventDefault();
    setUserShowBtnLoading(true);

    const loginData = {
      email: userEmail,
      password: userPassword,
      remember: userRemember,
    };

    Request.postRequestLogin(ApiUrl.login, loginData).then((response) => {
      setUserShowBtnLoading(false);
      if (response.status === 200) {
        AppConfig.setAccessToken(
          response.data.token,
          response.data.user.role.name
        );
        if (
          sessionStorage.getItem("path") &&
          privateRoutes().find((e) => {
            if (
              e.path.includes(
                sessionStorage.getItem("path").split("/").slice(0, 3).join("/")
              ) &&
              e.access.includes(response.data.user.role.name)
            ) {
              return true;
            } else {
              return false;
            }
          })
        ) {
          window.location.replace(sessionStorage.getItem("path"));
        } else {
          window.location.replace(Path.dashboard);
        }
      } else if (
        response.status === 401 ||
        response.status === 403 ||
        response.status === 422
      ) {
        setUserErrorList(response.data);
      }
    });
  };

  return (
    <>
      <div className="accordion form-logins" id="libraryLogin">
        <div className="login_bg staff_login">
          <h3 className="login_title">{t("identify_login_title")}</h3>
          <p className="login_note">{t("identify_login_text")}</p>
          <form className="w-100" onSubmit={(e) => userLogin(e)}>
            <div className="form-group">
              <input
                type="text"
                name="email"
                placeholder={t("identify_login_email_placeholder")}
                className={
                  "form-input" +
                  (userErrorList.email || userErrorList.error
                    ? " input-error"
                    : "")
                }
                onChange={(e) => setUserEmail(e.target.value)}
              />
              <i className="icon username-icon" />
              {userErrorList.email && (
                <ValidationMessage message={userErrorList.email[0]} />
              )}
              {userErrorList.error && (
                <ValidationMessage message={userErrorList.error} />
              )}
            </div>
            <div className="form-group">
              <input
                type={userShowPassword ? "text" : "password"}
                name="password"
                placeholder={t("identify_login_password_placeholder")}
                className={
                  "form-input" +
                  (userErrorList.password || userErrorList.error
                    ? " input-error"
                    : "")
                }
                autoComplete="off"
                onChange={(e) => setUserPassword(e.target.value)}
              />
              <i className="icon lock-icon" />

              <div
                className="show-hide"
                onClick={(e) => setUserShowPassword(!userShowPassword)}
              >
                {userShowPassword ? (
                  <i className="icon show-icon" />
                ) : (
                  <i className="icon hide-icon" />
                )}
              </div>
              {userErrorList.password && (
                <ValidationMessage message={userErrorList.password[0]} />
              )}
            </div>
            <div className="form-check-auth">
              <div className="form-group">
                <input
                  className="form-input-checkbox"
                  type="checkbox"
                  name="remember"
                  id="remember"
                  onChange={(e) =>
                    setUserRemember(e.target.checked ? "1" : "0")
                  }
                />
                <label
                  className="custom-control-label chechbox-elt remember-me-b"
                  htmlFor="remember"
                >
                  {t("identify_login_remember_me")}
                </label>
              </div>
              <div className="forgot-password">
                <Link to={Path.forgotPassword}>
                  {t("identify_login_forgot_password")}
                </Link>
              </div>
            </div>
            {userShowBtnLoading ? (
              <button type="button" className="button-blue mt-2 w-100 has-icon">
                <i className="spinner-icon" />
                {t("identify_login_button_loading")}
              </button>
            ) : (
              <button type="submit" className="button-blue mt-2 w-100">
                {t("identify_login_button")}
              </button>
            )}
          </form>
        </div>
      </div>
    </>
  );
};

export default Login;
