import { t } from "i18next";
import Swal from "sweetalert2";

import confirmDeleteIcon from "../assets/images/icons/confirm_delete_icon.svg";
import confirmRestoreIcon from "../assets/images/icons/confirm_restore_icon.svg";

export const deletePopup = (itemDesc) => {
  return Swal.fire({
    title: `${
      itemDesc
        ? t("confirm_delete_popup_text")
        : t("confirm_delete_popup_image_text")
    }`,
    text: `${itemDesc ? itemDesc : ""}`,
    showCancelButton: true,
    showCloseButton: true,
    confirmButtonText: t("confirm_delete_popup_yes_button"),
    cancelButtonText: t("confirm_delete_popup_no_button"),
    imageUrl: `${confirmDeleteIcon}`,
    imageHeight: 30,
    imageWidth: 30,
    imageAlt: "Confirmation image",
    customClass: {
      confirmButton: "swal-button-blue",
      cancelButton: "swal-button-border-blue",
      container: "confirmation-popup",
    },
    showClass: {
      popup: "",
    },
    hideClass: {
      popup: "",
    },
  });
};

export const restorePopup = (itemDesc) => {
  return Swal.fire({
    title: `${
      itemDesc
        ? t("confirm_restore_popup_text")
        : t("confirm_delete_popup_image_text")
    }`,
    text: `${itemDesc ? itemDesc : ""}`,
    showCancelButton: true,
    showCloseButton: true,
    confirmButtonText: t("confirm_delete_popup_yes_button"),
    cancelButtonText: t("confirm_delete_popup_no_button"),
    imageUrl: `${confirmRestoreIcon}`,
    imageHeight: 30,
    imageWidth: 30,
    imageAlt: "Confirmation image",
    customClass: {
      confirmButton: "swal-button-blue",
      cancelButton: "swal-button-border-blue",
      container: "confirmation-popup",
    },
    showClass: {
      popup: "",
    },
    hideClass: {
      popup: "",
    },
  });
};

export const deleteSitePopup = (itemDesc) => {
  var htmlContent = `${itemDesc}
    <div class="d-flex flex-column popup-message">
      <span>${t("confirm_delete_site_popup_text")}</span>
      <span>${t("confirm_delete_site_popup_text_2")}</span>
      <span>${t("confirm_delete_site_popup_text_3")}</span>
      <span>${t("confirm_delete_site_popup_text_4")}</span>
    </div>`;
  return Swal.fire({
    title: `${
      itemDesc
        ? t("confirm_delete_popup_text")
        : t("confirm_delete_popup_image_text")
    }`,
    html: htmlContent,
    showCancelButton: true,
    showCloseButton: true,
    confirmButtonText: t("confirm_delete_popup_yes_button"),
    cancelButtonText: t("confirm_delete_popup_no_button"),
    imageUrl: `${confirmDeleteIcon}`,
    imageHeight: 30,
    imageWidth: 30,
    imageAlt: "Confirmation image",
    customClass: {
      confirmButton: "swal-button-blue",
      cancelButton: "swal-button-border-blue",
      container: "confirmation-popup",
    },
    showClass: {
      popup: "",
    },
    hideClass: {
      popup: "",
    },
  });
};

export const deleteMemberPopup = (itemDesc) => {
  var htmlContent = `${itemDesc}
    <div class="d-flex flex-column popup-message">
      <span>${t("confirm_delete_member_popup_text")}</span>
      <span>${t("confirm_delete_member_popup_text_1")}</span>
    </div>`;
  return Swal.fire({
    title: `${
      itemDesc
        ? t("confirm_delete_popup_text")
        : t("confirm_delete_popup_image_text")
    }`,
    html: htmlContent,
    showCancelButton: true,
    showCloseButton: true,
    confirmButtonText: t("confirm_delete_popup_yes_button"),
    cancelButtonText: t("confirm_delete_popup_no_button"),
    imageUrl: `${confirmDeleteIcon}`,
    imageHeight: 30,
    imageWidth: 30,
    imageAlt: "Confirmation image",
    customClass: {
      confirmButton: "swal-button-blue",
      cancelButton: "swal-button-border-blue",
      container: "confirmation-popup",
    },
    showClass: {
      popup: "",
    },
    hideClass: {
      popup: "",
    },
  });
};

export const deleteCompanyPopup = (itemDesc) => {
  var htmlContent = `${itemDesc}
    <div class="d-flex flex-column popup-message">
      <span>${t("confirm_delete_company_popup_text")}</span>
      <span>${t("confirm_delete_company_popup_text_1")}</span>
    </div>`;
  return Swal.fire({
    title: `${
      itemDesc
        ? t("confirm_delete_popup_text")
        : t("confirm_delete_popup_image_text")
    }`,
    html: htmlContent,
    showCancelButton: true,
    showCloseButton: true,
    confirmButtonText: t("confirm_delete_popup_yes_button"),
    cancelButtonText: t("confirm_delete_popup_no_button"),
    imageUrl: `${confirmDeleteIcon}`,
    imageHeight: 30,
    imageWidth: 30,
    imageAlt: "Confirmation image",
    customClass: {
      confirmButton: "swal-button-blue",
      cancelButton: "swal-button-border-blue",
      container: "confirmation-popup",
    },
    showClass: {
      popup: "",
    },
    hideClass: {
      popup: "",
    },
  });
};

export const deleteSuperadminPopup = (itemDesc) => {
  var htmlContent = `${itemDesc}
    <div class="d-flex flex-column popup-message">
      <span>${t("confirm_delete_superadmin_popup_text")}</span>
      <span>${t("confirm_delete_superadmin_popup_text_1")}</span>
    </div>`;
  return Swal.fire({
    title: `${
      itemDesc
        ? t("confirm_delete_popup_text")
        : t("confirm_delete_popup_image_text")
    }`,
    html: htmlContent,
    showCancelButton: true,
    showCloseButton: true,
    confirmButtonText: t("confirm_delete_popup_yes_button"),
    cancelButtonText: t("confirm_delete_popup_no_button"),
    imageUrl: `${confirmDeleteIcon}`,
    imageHeight: 30,
    imageWidth: 30,
    imageAlt: "Confirmation image",
    customClass: {
      confirmButton: "swal-button-blue",
      cancelButton: "swal-button-border-blue",
      container: "confirmation-popup",
    },
    showClass: {
      popup: "",
    },
    hideClass: {
      popup: "",
    },
  });
};

export const deleteFolderPopup = (itemDesc) => {
  var htmlContent = `${itemDesc}
    <div class="d-flex flex-column popup-message">
      <span>${t("confirm_delete_folder_popup_text")}</span>
      <span>${t("confirm_delete_folder_popup_text_1")}</span>
      <span>${t("confirm_delete_folder_popup_text_2")}</span>
    </div>`;
  return Swal.fire({
    title: `${
      itemDesc
        ? t("confirm_delete_popup_text")
        : t("confirm_delete_popup_image_text")
    }`,
    html: htmlContent,
    showCancelButton: true,
    showCloseButton: true,
    confirmButtonText: t("confirm_delete_popup_yes_button"),
    cancelButtonText: t("confirm_delete_popup_no_button"),
    imageUrl: `${confirmDeleteIcon}`,
    imageHeight: 30,
    imageWidth: 30,
    imageAlt: "Confirmation image",
    customClass: {
      confirmButton: "swal-button-blue",
      cancelButton: "swal-button-border-blue",
      container: "confirmation-popup",
    },
    showClass: {
      popup: "",
    },
    hideClass: {
      popup: "",
    },
  });
};

export const deleteDocumentPopup = (itemDesc) => {
  var htmlContent = `${itemDesc}
    <div class="d-flex flex-column popup-message">
      <span>${t("confirm_delete_document_popup_text")}</span>
      <span>${t("confirm_delete_document_popup_text_1")}</span>
    </div>`;
  return Swal.fire({
    title: `${
      itemDesc
        ? t("confirm_delete_popup_text")
        : t("confirm_delete_popup_image_text")
    }`,
    html: htmlContent,
    showCancelButton: true,
    showCloseButton: true,
    confirmButtonText: t("confirm_delete_popup_yes_button"),
    cancelButtonText: t("confirm_delete_popup_no_button"),
    imageUrl: `${confirmDeleteIcon}`,
    imageHeight: 30,
    imageWidth: 30,
    imageAlt: "Confirmation image",
    customClass: {
      confirmButton: "swal-button-blue",
      cancelButton: "swal-button-border-blue",
      container: "confirmation-popup",
    },
    showClass: {
      popup: "",
    },
    hideClass: {
      popup: "",
    },
  });
};

export const deleteDocumentForeverPopup = (itemDesc) => {
  var htmlContent = `${itemDesc}
    <div class="d-flex flex-column popup-message">
      <span>${t("confirm_delete_document_forever_popup_text")}</span>
      <span>${t("confirm_delete_document_forever_popup_text_1")}</span>
    </div>`;
  return Swal.fire({
    title: `${
      itemDesc
        ? t("confirm_delete_popup_text")
        : t("confirm_delete_popup_image_text")
    }`,
    html: htmlContent,
    showCancelButton: true,
    showCloseButton: true,
    confirmButtonText: t("confirm_delete_popup_yes_button"),
    cancelButtonText: t("confirm_delete_popup_no_button"),
    imageUrl: `${confirmDeleteIcon}`,
    imageHeight: 30,
    imageWidth: 30,
    imageAlt: "Confirmation image",
    customClass: {
      confirmButton: "swal-button-blue",
      cancelButton: "swal-button-border-blue",
      container: "confirmation-popup",
    },
    showClass: {
      popup: "",
    },
    hideClass: {
      popup: "",
    },
  });
};

export const deleteClassificationPopup = (itemDesc) => {
  var htmlContent = `${itemDesc}
    <div class="d-flex flex-column popup-message">
      <span>${t("confirm_delete_classification_popup_text")}</span>
      <span>${t("confirm_delete_classification_popup_text_1")}</span>
      <span>${t("confirm_delete_classification_popup_text_2")}</span>
    </div>`;
  return Swal.fire({
    title: `${
      itemDesc
        ? t("confirm_delete_popup_text")
        : t("confirm_delete_popup_image_text")
    }`,
    html: htmlContent,
    showCancelButton: true,
    showCloseButton: true,
    confirmButtonText: t("confirm_delete_popup_yes_button"),
    cancelButtonText: t("confirm_delete_popup_no_button"),
    imageUrl: `${confirmDeleteIcon}`,
    imageHeight: 30,
    imageWidth: 30,
    imageAlt: "Confirmation image",
    customClass: {
      confirmButton: "swal-button-blue",
      cancelButton: "swal-button-border-blue",
      container: "confirmation-popup",
    },
    showClass: {
      popup: "",
    },
    hideClass: {
      popup: "",
    },
  });
};