import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import Navbar from "./Navbar";

const Header = () => {
  const { t } = useTranslation();

  return (
    <header className={`header full-height databank`} id="home">
      <Navbar />
      <div className="banner-container w-50 text-start databank-banner ">
        <motion.h1
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          className="title text-nowrap text-start"
        >
          {t("homepage_header_title")}
        </motion.h1>
        <motion.p
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          className="subtitle text-start"
        >
          {t("homepage_header_subtitle")}
        </motion.p>
      </div>
    </header>
  );
};

export default Header;
