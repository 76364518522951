import { Navigate } from "react-router-dom";
import { isLogged } from "../helpers/AppConfig";
import ProtectedRoute from "./ProtectedRoute";
import * as Path from "./GeneralPaths";

import Page404View from "../views/utility/Page404View";

import HomepageView from "../views/homepage/HomepageView";

import RegisterView from "../views/authentication/RegisterView";
import ForgotPasswordView from "../views/authentication/ForgotPasswordView";
import LoginView from "../views/authentication/LoginView";
import ResetPasswordView from "../views/authentication/ResetPasswordView";

import SuperAdminsView from "../views/panel/superadmins/SuperAdminsView";
import SitesView from "../views/panel/sites/SitesView";
import UsersView from "../views/panel/users/UsersView";
import CompaniesView from "../views/panel/companies/CompaniesView";
import DocumentsView from "../views/panel/sites/documents/DocumentsView";
import ClassificationView from "../views/panel/sites/classifications/ClassificationsView";
import TagsView from "../views/panel/sites/tags/TagsView";
import TrashView from "../views/panel/sites/trash/TrashView";
import ProfileView from "../views/panel/profile/ProfileView";
import MembersView from "../views/panel/sites/members/MembersView";
import TutorialView from "../views/panel/tutorial/TutorialView";

const privateRoutes = (role) => {
  const checkAuth = (route) => {
    if (!isLogged()) {
      return <Navigate to={Path.login} />;
    } else {
      return route;
    }
  };

  return [
    {
      path: Path.dashboard,
      element: checkAuth(
        <ProtectedRoute roles={["superadmin", "admin", "member"]}>
          {role === "superadmin" && <Navigate to={Path.superAdminList} />}
          {role === "admin" && <Navigate to={Path.usersList} />}
          {role === "member" && <Navigate to={Path.sitesList} />}
        </ProtectedRoute>
      ),
    },
    {
      path: Path.superAdminList,
      element: checkAuth(
        <ProtectedRoute roles={["superadmin"]}>
          <SuperAdminsView />
        </ProtectedRoute>
      ),
    },
    {
      path: Path.companiesList,
      element: checkAuth(
        <ProtectedRoute roles={["superadmin"]}>
          <CompaniesView />
        </ProtectedRoute>
      ),
    },
    {
      path: Path.sitesList,
      element: checkAuth(
        <ProtectedRoute roles={["admin", "member"]}>
          <SitesView />
        </ProtectedRoute>
      ),
    },
    {
      path: Path.usersList,
      element: checkAuth(
        <ProtectedRoute roles={["admin"]}>
          <UsersView />
        </ProtectedRoute>
      ),
    },
    {
      path: Path.siteDocumentsId,
      element: checkAuth(
        <ProtectedRoute roles={["admin", "member"]}>
          <DocumentsView />
        </ProtectedRoute>
      ),
    },
    {
      path: Path.siteDocumentsParentId,
      element: checkAuth(
        <ProtectedRoute roles={["admin", "member"]}>
          <DocumentsView />
        </ProtectedRoute>
      ),
    },
    {
      path: Path.siteClassificationsId,
      element: checkAuth(
        <ProtectedRoute roles={["admin", "member"]}>
          <ClassificationView />
        </ProtectedRoute>
      ),
    },
    {
      path: Path.siteClassificationsItemsId,
      element: checkAuth(
        <ProtectedRoute roles={["admin", "member"]}>
          <ClassificationView />
        </ProtectedRoute>
      ),
    },
    {
      path: Path.siteMembersListId,
      element: checkAuth(
        <ProtectedRoute roles={["admin", "member"]}>
          <MembersView />
        </ProtectedRoute>
      ),
    },
    {
      path: Path.siteTagsId,
      element: checkAuth(
        <ProtectedRoute roles={["admin", "member"]}>
          <TagsView />
        </ProtectedRoute>
      ),
    },
    {
      path: Path.siteTrashId,
      element: checkAuth(
        <ProtectedRoute roles={["admin", "member"]}>
          <TrashView />
        </ProtectedRoute>
      ),
    },
    {
      path: Path.profile,
      element: checkAuth(
        <ProtectedRoute roles={["superadmin", "admin", "member"]}>
          <ProfileView />
        </ProtectedRoute>
      ),
    },
    {
      path: Path.tutorial,
      element: checkAuth(
        <ProtectedRoute roles={["admin", "member"]}>
          <TutorialView />
        </ProtectedRoute>
      ),
    },
  ];
};

const publicRoutes = () => {
  const checkAuth = (route) => {
    if (isLogged()) {
      return <Navigate to={Path.dashboard} />;
    } else {
      return route;
    }
  };

  var privateRoutes = [];

  let publicArr = [
    {
      path: Path.homepage,
      element: <HomepageView />,
    },
    {
      path: Path.login,
      element: checkAuth(<LoginView />),
    },
    {
      path: Path.forgotPassword,
      element: checkAuth(<ForgotPasswordView />),
    },
    {
      path: Path.resetPasswordParam,
      element: checkAuth(<ResetPasswordView />),
    },
    {
      path: Path.ativateAccountParam,
      element: checkAuth(<RegisterView />),
    },
  ];

  var publicRoutes = publicArr.map((item) => ({
    path: item.path,
    element: item.element,
  }));

  let allRoutesArr = [...privateRoutes, ...publicRoutes];
  return allRoutesArr;
};

const helperRoutes = [{ path: Path.pageError404, element: <Page404View /> }];

export { privateRoutes, publicRoutes, helperRoutes };
