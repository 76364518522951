import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useUserdata } from "../../../../store/UserData";
import SimpleBar from "simplebar-react";
import Loading from "../../../../helpers/Loading";
import EditClassification from "./EditClassification";
import AddNewClassification from "./AddNewClassification";

import * as Path from "../../../../routes/GeneralPaths";
import * as Request from "../../../../api/Request";
import * as ApiUrl from "../../../../api/ApiUrl";
import * as Message from "../../../../helpers/Messages";
import * as ConfirmPopup from "../../../../helpers/ConfirmPopup";

const ClassificationsSidebar = ({
  hasNoClassificationId,
  renderItems,
  clickedItem,
}) => {
  const { t } = useTranslation("");
  const { siteId, classificationId } = useParams();
  const navigate = useNavigate();
  const userData = useUserdata((state) => state.userData);

  const ref = useRef();

  const [loading, setLoading] = useState(true);
  const [classificationsList, setClassificationsList] = useState([]);
  const [action, setAction] = useState(false);

  const listClassifications = () => {
    Request.getRequest(ApiUrl.classificationsList(siteId)).then((response) => {
      if (response.status === 200) {
        setClassificationsList(response.data.classifications);
        setLoading(false);
        setAction(false);
        renderItems(true);
      } else if (response.status === 403) {
        setTimeout(() => {
          navigate(-1);
        }, 2000);
        Message.errorMsg(response.data.error);
      } else if (response.status === 404) {
        setTimeout(() => {
          navigate(-1);
        }, 2000);
        Message.errorMsg(response.data.error);
      } else {
        setTimeout(() => {
          navigate(-1);
        }, 2000);
        Message.errorMsg(t("error_message"));
      }
    });
  };

  useEffect(() => {
    listClassifications();
  }, [siteId, action, hasNoClassificationId]);

  //   Add classification modal
  const [addClassificationModal, setAddClassificationModal] = useState(false);

  const setAddClassificiationVisible = (visible) => {
    setAddClassificationModal(visible);
    setClassificationPopupId("");
  };

  //   Edit classification modal
  const [classificationPopupId, setClassificationPopupId] = useState(false);
  const [classificationModal, setClassificationModal] = useState("");

  const setClassificiationVisible = (visible) => {
    setClassificationModal(visible);
    setClassificationPopupId("");
  };

  //   Delete classification
  const deleteClassification = (e, id, name) => {
    ConfirmPopup.deleteClassificationPopup(name).then((response) => {
      if (response.isConfirmed) {
        Request.deleteRequest(ApiUrl.classificationDelete(siteId) + id).then(
          (response) => {
            if (response.status === 200) {
              listClassifications();
              Message.successMsg(response.data.success);
              setAction(true);
            } else if (response.status === 404) {
              Message.errorMsg(response.data.error);
            } else {
              Message.errorMsg(t("error_message"));
            }
          }
        );
      }
    });
  };

  return (
    <div className="sidebar-content">
      <div className="mb-4">
        <div className="sidebar-filter-list">
          <div className="sidebar-item">
            <div className="sidebar-header">
              <div className="sidebar-title" type="button">
                {t("list_of_classifications")}
              </div>
              {(userData.role === "superadmin" ||
                userData.role === "admin") && (
                <button
                  className="sidebar-blue-button"
                  onClick={() => setAddClassificationModal(true)}
                >
                  <i className="icon plus-icon" />
                  {addClassificationModal && (
                    <AddNewClassification
                      onShowModal={addClassificationModal}
                      setAddVisible={(e) => setAddClassificiationVisible(e)}
                      setAction={(e) => setAction(e)}
                    />
                  )}
                </button>
              )}
            </div>
            <div
              id="sidebar-classifications"
              className="accordion-collapse collapsed"
              aria-labelledby="sidebarClassifications"
            >
              <ul className="list-unstyled">
                {loading ? (
                  <div className="text-center" colSpan="2">
                    <Loading />
                  </div>
                ) : (
                  <SimpleBar ref={ref} style={{ maxHeight: "270px" }}>
                    {classificationsList.map((classification) => (
                      <li
                        key={classification.id}
                        onClick={() => {
                          navigate(
                            Path.siteClassificationsItems(
                              siteId,
                              classification.id
                            )
                          );
                          clickedItem(true);
                        }}
                        className={
                          classificationId === `${classification.id}`
                            ? "active-item"
                            : "item"
                        }
                      >
                        <div className="classification-name">
                          {classification.name}
                        </div>
                        {(userData.role === "superadmin" ||
                          userData.role === "admin") && (
                          <div
                            className="classification-action"
                            onClick={(e) => e.stopPropagation()}
                          >
                            <button
                              type="button"
                              className="button-unstyled-icon me-3"
                              onClick={() => {
                                setClassificationModal(true);
                                setClassificationPopupId(classification.id);
                              }}
                            >
                              <i className="icon edit-icon" />
                            </button>
                            {classificationModal &&
                              classificationPopupId === classification.id && (
                                <EditClassification
                                  onShowModal={classificationModal}
                                  setClassificationVisible={
                                    setClassificiationVisible
                                  }
                                  setModified={setAction}
                                  classificationId={classification.id}
                                  classificationName={classification.name}
                                />
                              )}
                            <button
                              type="button"
                              className="button-unstyled-icon"
                              onClick={(e) => {
                                deleteClassification(
                                  e,
                                  classification.id,
                                  classification.name
                                );
                              }}
                            >
                              <i className="icon delete-icon" />
                            </button>
                          </div>
                        )}
                      </li>
                    ))}
                  </SimpleBar>
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClassificationsSidebar;
