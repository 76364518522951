import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import ListResponse from "../../../helpers/ListResponse";
import Loading from "../../../helpers/Loading";
import CustomPagination from "../../../helpers/CustomPagination";
import ValidationMessage from "../../../helpers/ValidationMessage";
import SearchFilter from "../../../helpers/SearchFilter";

import * as Request from "../../../api/Request";
import * as ApiUrl from "../../../api/ApiUrl";
import * as Message from "../../../helpers/Messages";
import * as Tooltip from "../../../helpers/Tooltip";
import * as ConfirmPopup from "../../../helpers/ConfirmPopup";

const Companies = () => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(true);
  const [listOfCompanies, setListOfCompanies] = useState([]);
  const [email, setEmail] = useState("");
  const [company_name, setCompanyName] = useState("");
  const [company_domain, setCompanyDomain] = useState("");
  const [term, setTerm] = useState("");
  const [error, setError] = useState({});
  const [searched, setSearched] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [searchBtnLoading, setSearchBtnLoading] = useState(false);
  const [activePage, setActivePage] = useState("");
  const [action, setAction] = useState(false);
  const [pagination, setPagination] = useState([]);

  useEffect(() => {
    listCompanies();
  }, [activePage, searched, action]);

  const listCompanies = (hasSearch) => {
    let request = ApiUrl.listCompanies;

    var searchParams = {};

    if (hasSearch) {
      hasSearch.preventDefault();
      setSearchBtnLoading(true);
    }

    if (!searched && activePage > 1) {
      searchParams = {
        page: activePage,
      };
    } else if (searched && activePage <= 1) {
      searchParams = {
        term: term,
      };
    } else if (searched && activePage > 1) {
      searchParams = {
        term: term,
        page: activePage,
      };
    }
    request = SearchFilter(searchParams, request);
    Request.getRequest(request).then((response) => {
      setSearchBtnLoading(false);
      if (response.status === 200) {
        setListOfCompanies(response.data.companies.data);
        setLoading(false);
        setPagination(response.data.companies);

        if (response.data.companies.data.length < 1 && activePage > 1) {
          setActivePage(activePage - 1);
          setAction(true);
        }

        setAction(false);
      } else {
        setListOfCompanies([]);
        setLoading(true);
      }
    });
  };

  const deleteCompany = (e, id, name) => {
    ConfirmPopup.deleteCompanyPopup(name).then((response) => {
      if (response.isConfirmed) {
        Request.deleteRequest(ApiUrl.deleteCompany + id).then((response) => {
          if (response.status === 200) {
            setAction(true);
            Message.successMsg(response.data.success);
          } else if (response.status === 404) {
            Message.errorMsg(response.data.error);
          } else {
            Message.errorMsg(t("error_message"));
          }
        });
      }
    });
  };

  const inviteCompany = (e) => {
    e.preventDefault(e);
    setBtnLoading(true);
    var data = {
      email: email,
      company_name: company_name,
      company_domain: company_domain,
    };
    Request.postRequest(ApiUrl.inviteCompany, data).then((response) => {
      setBtnLoading(false);
      if (response.status === 201) {
        setEmail("");
        setCompanyName("");
        setCompanyDomain("");
        setAction(true);
        setError({});
        Message.successMsg(response.data.success);
      } else if (response.status === 422) {
        setError(response.data);
      } else {
        Message.errorMsg(t("error_message"));
      }
    });
  };

  return (
    <>
      <div id="list-of-companies">
        <p className="partial-title mb-5">{t("list_of_companies")}</p>
        <div className="add-item pt-4 pb-2 px-4 mb-5">
          <form onSubmit={(e) => inviteCompany(e)}>
            <div className="row">
              <label>{t("invite_new_company")}</label>
              <div className="form-block col-12 col-md-3 col-xxl-3">
                <input
                  placeholder={t("company_invite_company_name_placeholder")}
                  className={
                    "form-input" + (error?.company_name ? " input-error" : "")
                  }
                  name="company_name"
                  type="text"
                  value={company_name}
                  onChange={(e) => setCompanyName(e.target.value)}
                />
                {error?.company_name && (
                  <ValidationMessage message={error?.company_name[0]} />
                )}
              </div>
              <div className="form-block col-12 col-md-3 col-xxl-3">
                <input
                  placeholder={t("company_invite_company_domain_placeholder")}
                  className={
                    "form-input" + (error?.company_domain ? " input-error" : "")
                  }
                  name="company_domain"
                  type="text"
                  value={company_domain}
                  onChange={(e) => setCompanyDomain(e.target.value)}
                />
                {error?.company_domain && (
                  <ValidationMessage message={error?.company_domain[0]} />
                )}
              </div>
              <div className="form-block col-12 col-md-3 col-xxl-3">
                <input
                  placeholder={t("company_invite_placeholder")}
                  className={
                    "form-input" + (error?.email ? " input-error" : "")
                  }
                  name="email"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                {error?.email && (
                  <ValidationMessage message={error?.email[0]} />
                )}
              </div>
              <div className="col-12 col-md-3 col-md-4 col-xxl-3 pb-3">
                {btnLoading ? (
                  <button type="button" className="button-blue has-icon">
                    <i className="icon spinner-icon me-2" />
                    {t("company_inviting")}
                  </button>
                ) : (
                  <button type="submit" className="button-blue has-icon">
                    {t("company_invite")}
                  </button>
                )}
              </div>
            </div>
          </form>
        </div>
        <div className="search-item px-4 mb-5">
          <form onSubmit={(e) => listCompanies(e)}>
            <div className="row">
              <label>{t("company_list_search_label")}</label>
              <div className="form-block col-12 col-md-5 col-xxl-3">
                <input
                  placeholder={t("company_list_search_name")}
                  className="form-input"
                  name="term"
                  type="text"
                  onChange={(e) => setTerm(e.target.value)}
                />
                {error?.term && <ValidationMessage message={error?.term[0]} />}
              </div>
              <div className="form-block col-12 col-sm-6 col-md-4 pb-3">
                {searchBtnLoading ? (
                  <button type="button" className="button-blue has-icon">
                    <i className="icon spinner-icon me-2" />
                    {t("company_list_searching")}
                  </button>
                ) : (
                  <button
                    type="submit"
                    className="button-blue has-icon"
                    onClick={() => {
                      setSearched(true);
                      setActivePage("1");
                    }}
                  >
                    <i className="icon search-icon me-2" />
                    {t("company_list_search")}
                  </button>
                )}
              </div>
            </div>
          </form>
        </div>
        <div className="table-list mb-5">
          <table role="table" className="w-100">
            <thead>
              <tr role="row">
                <th role="columnheader" className="text-left">
                  {t("company_name")}
                </th>
                <th role="columnheader" className="text-center">
                  {t("company_admin")}
                </th>
                <th role="columnheader" className="text-center">
                  {t("company_email")}
                </th>
                <th role="columnheader" className="text-center">
                  {t("company_domain")}
                </th>
                <th role="columnheader" className="text-center">
                  {t("company_description")}
                </th>
                <th role="columnheader" className="text-center">
                  {t("company_action")}
                </th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td className="text-center" colSpan="5">
                    <Loading />
                  </td>
                </tr>
              ) : (
                listOfCompanies.map((company, index) => (
                  <tr key={company.id} role="row">
                    <td role="cell" data-label={t("company_name")}>
                      {company.name}
                    </td>
                    <td
                      role="cell"
                      data-label={t("company_admin")}
                      className="text-center"
                    >
                      {company.users.map((user) =>
                        user.first_name
                          ? user.first_name + " " + user.last_name
                          : "-"
                      )}
                    </td>
                    <td
                      role="cell"
                      data-label={t("company_email")}
                      className="text-center"
                    >
                      {company.users.map((user) => user.email)}
                    </td>
                    <td
                      role="cell"
                      data-label={t("company_domain")}
                      className="text-center"
                    >
                      {company.domain ? company.domain : "-"}
                    </td>
                    <td
                      role="cell"
                      data-label={t("company_description")}
                      className="text-center"
                    >
                      <div className="d-inline-flex">
                        {company.description
                          ? Tooltip.tooltipDisabledMobile(
                              index,
                              company.description
                            )
                          : "-"}
                      </div>
                    </td>
                    <td
                      role="cell"
                      data-label={t("company_action")}
                      className="text-center"
                    >
                      <div className="row justify-content-around mr-auto ml-auto action-icons">
                        <div className="delete">
                          <button
                            type="submit"
                            className="button-unstyled-icon"
                            onClick={(e) =>
                              deleteCompany(e, company.id, company.name)
                            }
                          >
                            <i className="icon delete-icon" />
                          </button>
                        </div>
                      </div>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
          <ListResponse
            totalItems={pagination && pagination.total}
            loading={loading}
            searched={searched}
          />
        </div>
      </div>
      <CustomPagination
        data={pagination}
        setActivePage={(e) => {
          setActivePage(e);
          Object.keys(error).length > 0 && setSearched(false);
        }}
      />
    </>
  );
};

export default Companies;
