import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import ValidationMessage from "../../../helpers/ValidationMessage";

import * as Request from "../../../api/Request";
import * as ApiUrl from "../../../api/ApiUrl";
import * as Message from "../../../helpers/Messages";

const ChangePassword = () => {
  const { t } = useTranslation();

  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [errorList, setErrorList] = useState([]);
  const [btnLoading, setBtnLoading] = useState(false);
  const [currentPasswordShow, setCurrentPasswordShow] = useState(false);
  const [newPasswordShow, setNewPasswordShow] = useState(false);
  const [confirmPasswordShow, setConfirmPasswordShow] = useState(false);

  const changePassword = (e) => {
    e.preventDefault();
    setBtnLoading(true);
    const newPasswordData = {
      current_password: currentPassword,
      new_password: newPassword,
      new_password_confirmation: confirmNewPassword,
    };
    Request.postRequest(ApiUrl.changePassword, newPasswordData).then(
      (response) => {
        setBtnLoading(false);
        if (response.status === 422) {
          setErrorList(response.data);
        } else if (response.status === 200) {
          setErrorList([]);
          setCurrentPassword("");
          setNewPassword("");
          setConfirmNewPassword("");
          Message.successMsg(response.data.success);
        }
      }
    );
  };

  return (
    <Fragment>
      <form className="mw-100 pt-5 px-0" onSubmit={(e) => changePassword(e)}>
        <h1 className="fw-bold fs-2  pb-5">{t("change_password_title")}</h1>
        <div className="row">
          <div className="col-lg-4 col-md-4 col-sm-12 col-12">
            <label>{t("old_password_label")}</label>
            <div className="form-group">
              <input
                type={currentPasswordShow ? "text" : "password"}
                className={
                  "form-input ps-2" +
                  (errorList.current_password ? " input-error" : "")
                }
                name="current_password"
                placeholder={t("old_password_placeholder")}
                onChange={(e) => setCurrentPassword(e.target.value)}
                autoComplete="off"
                value={currentPassword}
              />
              {errorList.current_password && (
                <ValidationMessage message={errorList.current_password[0]} />
              )}
              {errorList.error && (
                <ValidationMessage message={errorList.error} />
              )}
              <div
                id="current"
                className="show-hide "
                onClick={() => setCurrentPasswordShow(!currentPasswordShow)}
              >
                {currentPasswordShow ? (
                  <i className="icon show-icon" />
                ) : (
                  <i className="icon hide-icon" />
                )}
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12 col-12">
            <label>{t("new_password_label")}</label>
            <div className="form-group">
              <input
                type={newPasswordShow ? "text" : "password"}
                className={
                  "form-input ps-2" +
                  (errorList.new_password ? " input-error" : "")
                }
                name="new_password"
                placeholder={t("new_password_placeholder")}
                onChange={(e) => setNewPassword(e.target.value)}
                autoComplete="off"
                value={newPassword}
              />
              {errorList.new_password && (
                <ValidationMessage message={errorList.new_password[0]} />
              )}
              <div
                className="show-hide"
                onClick={(e) => setNewPasswordShow(!newPasswordShow)}
              >
                {newPasswordShow ? (
                  <i className="icon show-icon" />
                ) : (
                  <i className="icon hide-icon" />
                )}
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12 col-12">
            <label className=" text-nowrap">
              {t("confirm_new_password_label")}
            </label>
            <div className="form-group">
              <input
                type={confirmPasswordShow ? "text" : "password"}
                name="new_password_confirmation"
                placeholder={t("identify_login_password_placeholder")}
                className={
                  "form-input ps-2" +
                  (errorList.new_password_confirmation ? " input-error" : "")
                }
                autoComplete="off"
                value={confirmNewPassword}
                onChange={(e) => setConfirmNewPassword(e.target.value)}
              />
              {errorList.new_password_confirmation && (
                <ValidationMessage
                  message={errorList.new_password_confirmation[0]}
                />
              )}
              <div
                className="show-hide"
                onClick={(e) => setConfirmPasswordShow(!confirmPasswordShow)}
              >
                {confirmPasswordShow ? (
                  <i className="icon show-icon" />
                ) : (
                  <i className="icon hide-icon" />
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 text-end align-self-end">
          {btnLoading ? (
            <button type="button" className="button-blue has-icon">
              <i className="spinner-icon" />
              {t("change_password_save_button_loading")}
            </button>
          ) : (
            <button
              type="submit"
              className="button-blue"
              onClick={(e) => changePassword(e)}
            >
              {t("change_password_save_button")}
            </button>
          )}
        </div>
      </form>
    </Fragment>
  );
};
export default ChangePassword;
