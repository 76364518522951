import React, { Fragment, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useUserdata } from "../../../store/UserData";
import ValidationMessage from "../../../helpers/ValidationMessage";

import * as Request from "../../../api/Request";
import * as ApiUrl from "../../../api/ApiUrl";
import * as Message from "../../../helpers/Messages";
import * as AppConfig from "../../../helpers/AppConfig";
import * as Path from "../../../routes/GeneralPaths";

const EditProfile = ({ onSetLoading }) => {
  const { t } = useTranslation();
  const userData = useUserdata((state) => state.userData);
  const addLoggedUserData = useUserdata((state) => state.addUserData);
  const navigate = useNavigate();

  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [companyDescritption, setCompanyDescription] = useState("");
  const [errorList, setErrorList] = useState([]);
  const [btnLoading, setBtnLoading] = useState(false);
  const [action, setAction] = useState(false);

  if (Object.keys(userData).length > 0) {
    if (userData.role === AppConfig.authRole()) {
      var superadmin = userData.role === "superadmin";
      var admin = userData.role === "admin";
      var member = userData.role === "member";
    } else {
      Request.postRequest(ApiUrl.logout).then((response) => {
        if (response.status === 200) {
          AppConfig.deleteAccessToken();
          navigate(Path.login);
        } else {
          AppConfig.deleteAccessToken();
          navigate(Path.login);
        }
      });
    }
  }

  useEffect(() => {
    postProfileData();
  }, [action]);

  const postProfileData = () => {
    Request.postRequest(ApiUrl.myProfile).then((response) => {
      if (response.status === 200) {
        onSetLoading(false);
        setName(response.data.user[0].first_name);
        setSurname(response.data.user[0].last_name);
        setPhoneNumber(response.data.user[0].phone);
        setEmail(response.data.user[0].email);

        if (response.data.user[0].company_id) {
          setCompanyName(response.data.user[0].company.name);
          setCompanyDescription(response.data.user[0].company.description);
        }

        action &&
          addLoggedUserData({
            ...userData,
            first_name: response.data.user[0].first_name,
            last_name: response.data.user[0].last_name,
          });

        setAction(false);
      } else if (response.status === 422) {
        Message.errorMsg(response.data.error);
      } else {
        Message.errorMsg(t("error_message"));
      }
    });
  };

  const updateProfileData = (e) => {
    e.preventDefault();
    setBtnLoading(true);

    const profileDataUpdated = {
      first_name: name,
      last_name: surname,
      phone: phoneNumber,
      company_name: companyName,
      company_description: companyDescritption,
    };

    Request.postRequest(ApiUrl.updateProfile, profileDataUpdated).then(
      (response) => {
        setBtnLoading(false);
        if (response.status === 200) {
          setErrorList([]);
          setAction(true);
          Message.successMsg(response.data.success);
        } else if (response.status === 422) {
          setErrorList(response.data);
        } else {
          Message.errorMsg(t("error_message"));
        }
      }
    );
  };

  return (
    <>
      <Fragment>
        <div className="mw-100 pb-5">
          <div className="d-flex justify-content-start pb-md-5">
            <h1 className="fw-bold fs-2">{t("my_profile_edit_profile")}</h1>
          </div>
          <form className="w-100">
            <div className="row">
              <div className="form-block col-lg-6 col-md-6 col-sm-12 col-12">
                <label>{t("my_profile_name_label")}</label>
                <input
                  type="text"
                  name="first_name"
                  className={
                    "form-input" + (errorList.first_name ? " input-error" : "")
                  }
                  placeholder={t("my_profile_name_placeholder")}
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                {errorList.first_name && (
                  <ValidationMessage message={errorList.first_name[0]} />
                )}
              </div>
              <div className="form-block col-lg-6  col-md-6 col-sm-12 col-12">
                <label>{t("my_profile_email_label")}</label>
                <input
                  type="email"
                  name="email"
                  className="form-input"
                  autoComplete="off"
                  placeholder={t("my_profile_email_placeholder")}
                  value={email}
                  readOnly={true}
                />
              </div>
            </div>
            <div className="row">
              <div className="form-block col-lg-6 col-md-6 col-sm-12 col-12">
                <label>{t("my_profile_surname_label")}</label>
                <input
                  type="text"
                  name="last_name"
                  className={
                    "form-input" + (errorList.last_name ? " input-error" : "")
                  }
                  placeholder={t("my_profile_surname_placeholder")}
                  value={surname}
                  onChange={(e) => setSurname(e.target.value)}
                />
                {errorList.last_name && (
                  <ValidationMessage message={errorList.last_name[0]} />
                )}
              </div>
              <div className="form-block col-lg-6 col-md-6 col-sm-12 col-12">
                <label>{t("my_profile_phone_label")}</label>
                <input
                  type="tel"
                  name="phone"
                  className={
                    "form-input" + (errorList.phone ? " input-error" : "")
                  }
                  placeholder={t("my_profile_phone_placeholder")}
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                />
                {errorList.phone && (
                  <ValidationMessage message={errorList.phone[0]} />
                )}
              </div>
            </div>
            {admin && !superadmin && (
              <div className="row">
                <div className="form-block col-lg-6 col-md-6 col-sm-12 col-12">
                  <label>{t("my_profile_company_name_label")}</label>
                  <input
                    type="text"
                    name="name"
                    className={
                      "form-input" + (errorList.name ? " input-error" : "")
                    }
                    placeholder={t("my_profile_company_name_placeholder")}
                    value={companyName}
                    onChange={(e) => setCompanyName(e.target.value)}
                  />
                  {errorList.phone && (
                    <ValidationMessage message={errorList.name[0]} />
                  )}
                </div>
                <div className="form-block col-lg-6 col-md-6 col-sm-12 col-12">
                  <label>{t("my_profile_company_description_label")}</label>
                  <input
                    type="text"
                    name="description"
                    className={
                      "form-input" +
                      (errorList.description ? " input-error" : "")
                    }
                    placeholder={t(
                      "my_profile_company_description_placeholder"
                    )}
                    value={companyDescritption}
                    onChange={(e) => setCompanyDescription(e.target.value)}
                  />
                  {errorList.phone && (
                    <ValidationMessage message={errorList.description[0]} />
                  )}
                </div>
              </div>
            )}
            {member && !superadmin && (
              <div className="row">
                <div className="form-block col-lg-6 col-md-6 col-sm-12 col-12">
                  <label>{t("my_profile_company_name_label")}</label>
                  <input
                    type="text"
                    name="name"
                    className={
                      "form-input" + (errorList.name ? " input-error" : "")
                    }
                    placeholder={t("my_profile_company_name_placeholder")}
                    value={companyName}
                    readOnly={true}
                  />
                  {errorList.phone && (
                    <ValidationMessage message={errorList.name[0]} />
                  )}
                </div>
                <div className="form-block col-lg-6 col-md-6 col-sm-12 col-12">
                  <label>{t("my_profile_company_description_label")}</label>
                  <input
                    type="text"
                    name="description"
                    className={
                      "form-input" +
                      (errorList.description ? " input-error" : "")
                    }
                    placeholder={t(
                      "my_profile_company_description_placeholder"
                    )}
                    value={companyDescritption}
                    readOnly={true}
                  />
                  {errorList.phone && (
                    <ValidationMessage message={errorList.description[0]} />
                  )}
                </div>
              </div>
            )}
            <div className="float-end">
              {btnLoading ? (
                <button type="button" className="button-blue has-icon">
                  <i className="spinner-icon" />
                  {t("my_profile_save_btn_loading")}
                </button>
              ) : (
                <button
                  type="submit"
                  className="button-blue"
                  onClick={(e) => updateProfileData(e)}
                >
                  {t("my_profile_save_button")}
                </button>
              )}
            </div>
          </form>
        </div>
      </Fragment>
    </>
  );
};

export default EditProfile;
