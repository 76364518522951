import moment from "moment/moment";
import { useTranslation } from "react-i18next";
import CustomPagination from "../../../../../helpers/CustomPagination";
import ListResponse from "../../../../../helpers/ListResponse";
import Loading from "../../../../../helpers/Loading";
import { FileIcon, defaultStyles } from "react-file-icon";
import { useEffect, useState } from "react";
import EditFile from "./EditFile";
import { useUserdata } from "../../../../../store/UserData";
import { useParams } from "react-router-dom";
import Select from "react-select";
import ViewFile from "./ViewFile";

import * as ConfirmPopup from "../../../../../helpers/ConfirmPopup";
import * as ApiUrl from "../../../../../api/ApiUrl";
import * as Request from "../../../../../api/Request";
import * as Message from "../../../../../helpers/Messages";

const FilesList = ({
  files,
  loading,
  btnLoading,
  setActivePage,
  setAction,
  setSelectedClassifications,
  setSelectedTags,
  setSelectedName,
  setBtnLoading,
}) => {
  const { t } = useTranslation();
  const { siteId } = useParams();
  const userData = useUserdata((state) => state.userData);

  // Documents search
  const [classifications, setClassifications] = useState([]);
  const [tags, setTags] = useState([]);
  const [searchLoading, setSearchLoading] = useState(true);

  const [documentName, setDocumentName] = useState("");
  const [items, setItems] = useState({});
  const [documentTags, setDocumentTags] = useState([]);

  // Edit file modal
  const [showModal, setShowModal] = useState(false);
  const [fileId, setFileId] = useState("");

  const setFolderVisible = (visible) => {
    setShowModal(visible);
    setFileId("");
  };

  // View file modal
  const [showViewModal, setShowViewModal] = useState(false);
  const [viewFileId, setViewFileId] = useState("");

  const setViewFileVisible = (visible) => {
    setShowViewModal(visible);
    setViewFileId("");
  };

  //   Delete document
  const deleteDocument = (id, name) => {
    ConfirmPopup.deleteDocumentPopup(name).then((response) => {
      if (response.isConfirmed) {
        Request.deleteRequest(ApiUrl.deleteDocument + id).then((response) => {
          if (response.status === 200) {
            Message.successMsg(response.data.success);
            setAction(true);
          } else if (response.status === 404) {
            Message.errorMsg(response.data.error);
          } else {
            Message.errorMsg(t("error_message"));
          }
        });
      }
    });
  };

  // This function will download file
  const downloadFile = (file_id, file_path) => {
    const formData = new FormData();
    formData.append("document_id", file_id);

    Request.postRequestDownload(ApiUrl.downloadDoc, {
      document_id: file_id,
    }).then(async (response) => {
      if (response.status === 200) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", file_path);
        // Append to html link element page
        document.body.appendChild(link);
        // Start download
        link.click();
        // Clean up and remove the link
        link.parentNode.removeChild(link);
      } else {
        let errorString = response.data;

        if (
          response.request.responseType === "blob" &&
          response.data instanceof Blob &&
          response.data.type &&
          response.data.type.toLowerCase().indexOf("json") !== -1
        ) {
          errorString = JSON.parse(await response.data.text());
        }
        Message.errorMsg(errorString.error);
      }
    });
  };

  useEffect(() => {
    !loading &&
      Request.getRequest(ApiUrl.documentDetails(siteId)).then((response) => {
        if (response.status === 200) {
          setClassifications(response.data.classifications);
          setTags(response.data.tags);
          setSearchLoading(false);
        }
      });
  }, [loading]);

  const searchSubmit = (e) => {
    e.preventDefault();
    setSelectedClassifications(items);
    setSelectedTags(documentTags);
    setSelectedName(documentName);
    setBtnLoading(true);
  };

  return (
    <>
      <div className="folders-title">{t("site_documents_label")}</div>
      {!searchLoading && (
        <form className="documents-search" onSubmit={(e) => searchSubmit(e)}>
          <div className="form-block">
            <label className="d-flex text-left">
              {t("site_documents_search_name_label")}
            </label>
            <input
              type="text"
              name="name"
              className="form-input"
              onChange={(e) => setDocumentName(e.target.value)}
              value={documentName}
              placeholder={t("site_documents_search_name_placeholder")}
            />
          </div>
          {classifications.map((item) => (
            <div className="form-block" key={item.id}>
              <label className="d-flex text-left">{item.name}</label>
              <Select
                className="form-react-select"
                classNamePrefix="select_input"
                name="items"
                isClearable={true}
                placeholder={t(
                  "site_documents_search_classification_placeholder"
                )}
                onChange={(e) =>
                  setItems({
                    ...items,
                    [`item[${item.id}]`]: e ? e.value : "",
                  })
                }
                isSearchable={true}
                options={item.items.map((items) => ({
                  value: items.id,
                  label: items.name,
                }))}
              />
            </div>
          ))}
          <div className="form-block">
            <label className="d-flex text-left">
              {t("site_documents_search_tag_label")}
            </label>
            <Select
              className="form-react-select"
              isMulti
              classNamePrefix="select_input"
              name="tags"
              isClearable={true}
              placeholder={t("site_documents_search_tag_placeholder")}
              onChange={(e) => (e ? setDocumentTags(e) : setDocumentTags([]))}
              value={documentTags}
              isSearchable={true}
              options={tags.map((tag) => ({
                value: tag.id,
                label: tag.name,
              }))}
            />
          </div>
          <div className="form-block align-self-end">
            {btnLoading ? (
              <button type="button" className="button-blue has-icon">
                <i className="icon spinner-icon me-2" />
                {t("site_documents_search_searching_button")}
              </button>
            ) : (
              <button type="submit" className="button-blue">
                {t("site_documents_search_search_button")}
              </button>
            )}
          </div>
        </form>
      )}
      <div className="table-list-no-bg mb-5">
        <table role="table" className="w-100">
          <thead>
            <tr role="row">
              <th
                role="columnheader"
                className="text-left"
                style={{ width: "25%" }}
              >
                {t("site_documents_table_path")}
              </th>
              <th
                role="columnheader"
                className="text-left"
                style={{ width: "25%" }}
              >
                {t("site_documents_table_name")}
              </th>
              <th
                role="columnheader"
                className="text-left"
                style={{ width: "20%" }}
              >
                {t("site_documents_table_owner")}
              </th>
              <th
                role="columnheader"
                className="text-left"
                style={{ width: "15%" }}
              >
                {t("site_documents_table_created_at")}
              </th>
              <th
                role="columnheader"
                className="text-center"
                style={{ width: "15%" }}
              >
                {t("site_documents_table_action")}
              </th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td className="text-center" colSpan="5">
                  <Loading />
                </td>
              </tr>
            ) : (
              files.data.map((file) => (
                <tr
                  key={file.id}
                  role="row"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setViewFileId(file.id);
                    setShowViewModal(true);
                  }}
                >
                  <td role="cell" data-label={t("site_documents_table_path")}>
                    <div className="d-flex align-items-center gap-2">
                      <span style={{ maxWidth: "20px" }}>
                        <FileIcon
                          extension={file.path.split(".").pop()}
                          {...defaultStyles[file.path.split(".").pop()]}
                        />
                      </span>
                      {file.path.replace(/^\d+_/, "")}
                    </div>
                  </td>
                  <td role="cell" data-label={t("site_documents_table_name")}>
                    {file.name}
                  </td>
                  <td role="cell" data-label={t("site_documents_table_owner")}>
                    {file.owner.first_name + " " + file.owner.last_name}
                  </td>
                  <td
                    role="cell"
                    data-label={t("site_documents_table_created_at")}
                  >
                    {moment(file.created_at).format("DD MMMM, YYYY")}
                  </td>
                  <td
                    role="cell"
                    data-label={t("site_documents_table_action")}
                    className="text-center"
                    style={{ cursor: "default" }}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <div className="row justify-content-center mr-auto ml-auto action-icons">
                      <div className="download">
                        <button
                          className="button-unstyled-icon"
                          onClick={() =>
                            downloadFile(
                              file.id,
                              file.path.replace(/^\d+_/, "")
                            )
                          }
                        >
                          <i className="icon download-icon-gray" />
                        </button>
                      </div>
                      {userData.role_id !== file.owner_id && (
                        <>
                          <div className="edit">
                            <button
                              className="button-unstyled-icon"
                              onClick={(e) => {
                                setShowModal(true);
                                setFileId(file.id);
                              }}
                            >
                              <i className="icon edit-icon" />
                            </button>
                            {showModal && fileId === file.id && (
                              <EditFile
                                onShowModal={showModal}
                                setAction={(e) => setAction(e)}
                                setFileVisible={(e) => setFolderVisible(e)}
                                fileId={file.id}
                              />
                            )}
                          </div>
                          <div className="delete">
                            <button
                              type="submit"
                              className="button-unstyled-icon"
                              onClick={() => {
                                deleteDocument(file.id, file.name);
                              }}
                            >
                              <i className="icon delete-icon" />
                            </button>
                          </div>
                        </>
                      )}
                    </div>
                  </td>

                  {showViewModal && viewFileId === file.id && (
                    <ViewFile
                      onShowModal={showViewModal}
                      setFileVisible={(e) => setViewFileVisible(e)}
                      fileId={file.id}
                    />
                  )}
                </tr>
              ))
            )}
          </tbody>
        </table>
        <ListResponse
          totalItems={files && files.total}
          loading={loading}
          searched={false}
        />
      </div>
      <CustomPagination data={files} setActivePage={(e) => setActivePage(e)} />
    </>
  );
};

export default FilesList;
