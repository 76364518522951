import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams, useNavigate } from "react-router-dom";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import ValidationMessage from "../../../../../helpers/ValidationMessage";

import * as Request from "../../../../../api/Request";
import * as ApiUrl from "../../../../../api/ApiUrl";
import * as Message from "../../../../../helpers/Messages";
import * as Path from "../../../../../routes/GeneralPaths";

const CreateNewFolder = ({ onShowModal, setFolderVisible, setAction }) => {
  const { t } = useTranslation("");
  const navigate = useNavigate();
  const { siteId, parentId } = useParams();
  
  const [name, setName] = useState("");
  const [btnLoading, setBtnLoading] = useState(false);
  const [error, setError] = useState([]);

  const onClose = () => {
    setName("");
    setFolderVisible(false);
    setError([]);
  };

  const createFolder = (e) => {
    e.preventDefault();
    setBtnLoading(true);
    if (parentId) {
      var data = {
        name: name,
        site_id: siteId,
        parent_id: parentId,
      };
    } else {
      var data = {
        name: name,
        site_id: siteId,
      };
    }

    Request.postRequest(ApiUrl.storeFolder, data).then((response) => {
      setBtnLoading(false);
      if (response.status === 200) {
        Message.successMsg(response.data.success);
        onClose();
        setAction(true);
        setError([]);
        navigate(Path.siteDocuments(siteId, parentId));
      } else if (response.status === 422) {
        setError(response.data);
      } else {
        Message.errorMsg(t("error_message"));
      }
    });
  };

  return (
    <Modal isOpen={onShowModal} toggle={(e) => onClose(e)} size="md" centered>
      <ModalHeader
        className="text-dark font-size-15"
        toggle={(e) => onClose(e)}
        tag="span"
      >
        {t("create_folder_popup_title")}
      </ModalHeader>
      <form onSubmit={(e) => createFolder(e)} className="text-center">
        <ModalBody>
          <div className="form-block row mb-0">
            <div className="col-12 mb-3">
              <input
                type="text"
                name="name"
                className={"form-input" + (error?.name ? " input-error" : "")}
                onChange={(e) => setName(e.target.value)}
                value={name}
                placeholder={t("create_folder_name_popup_placeholder")}
              />
              {error?.name && <ValidationMessage message={error?.name} />}
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="col-12 d-flex m-0 p-0">
            <div className="col-6 pe-2">
              {btnLoading ? (
                <button className="button-blue w-100 has-icon" type="button">
                  <i className="icon spinner-icon me-2" />
                  {t("create_folder_saving")}
                </button>
              ) : (
                <button type="submit" className="button-blue w-100">
                  {t("create_folder_save")}
                </button>
              )}
            </div>
            <div className="col-6 ps-2">
              <button
                className="button-blue-border w-100"
                type="button"
                onClick={() => onClose()}
              >
                {t("create_folder_cancel")}
              </button>
            </div>
          </div>
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default CreateNewFolder;
