import { t } from "i18next";

const CheckRelationToSite = (data) => {
  switch (data) {
    case "owner":
      return t("site_list_relation_to_site_owner");
    case "member":
      return t("site_list_relation_to_site_member");
    case "other":
      return t("site_list_relation_to_site_other");
    default:
      return "";
  }
};

export default CheckRelationToSite;
