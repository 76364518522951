import React from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import Sidebar from "../../../components/layouts/sidebar/Sidebar";
import Profile from "../../../components/panel/profile/Profile";

const ProfileView = () => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{t("my_profile_tab_title")}</title>
      </Helmet>
      <Sidebar>
        <Profile />
      </Sidebar>
    </>
  );
};

export default ProfileView;
