import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();

  return (
    <footer className="footer">
      <div className="row">
        <div className="col-md-6 copyright">{t("panel_footer_copyright")}</div>
        <div className="col-md-6 developed-by">
          {t("panel_footer_powered")} <i className="icon heart-icon mx-1" />{" "}
          {t("panel_footer_powered_by")}{" "}
          <a
            href="https://abp.al/en/"
            target="_blank"
            className="ms-1"
            rel="noreferrer"
          >
            {t("panel_footer_company_name")}
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
