import ContactUs from "./ContactUs";
import Features from "./Features";
import MainStructure from "./layouts/MainStructure";

const Homepage = () => {
  return (
    <MainStructure>
      <Features />
      <ContactUs />
    </MainStructure>
  );
};

export default Homepage;
