import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import SearchFilter from "../../../helpers/SearchFilter";
import ListResponse from "../../../helpers/ListResponse";
import ValidationMessage from "../../../helpers/ValidationMessage";
import Loading from "../../../helpers/Loading";
import CustomPagination from "../../../helpers/CustomPagination";

import * as Request from "../../../api/Request";
import * as ApiUrl from "../../../api/ApiUrl";
import * as Message from "../../../helpers/Messages";
import * as ConfirmPopup from "../../../helpers/ConfirmPopup";

const SuperAdminsList = () => {
  const { t } = useTranslation();

  const [superAdmins, setSuperAdmins] = useState([]);
  const [loading, setLoading] = useState(true);
  const [email, setEmail] = useState("");
  const [error, setError] = useState({});
  const [btnLoading, setBtnLoading] = useState(false);
  const [activePage, setActivePage] = useState("");
  const [action, setAction] = useState(false);
  const [pagination, setPagination] = useState([]);

  useEffect(() => {
    listSuperAdmins();
  }, [activePage, action]);

  const listSuperAdmins = () => {
    let request = ApiUrl.listSuperadmins;

    var searchParams = {};

    if (activePage > 1) {
      searchParams = {
        page: activePage,
      };
    } else if (activePage > 1) {
      searchParams = {
        page: activePage,
      };
    }
    request = SearchFilter(searchParams, request);

    Request.getRequest(request).then((response) => {
      if (response.status === 200) {
        setSuperAdmins(response.data.users.data);
        setLoading(false);
        setError({});
        setPagination(response.data.users);

        if (response.data.users.data.length < 1 && activePage > 1) {
          setActivePage(activePage - 1);
          setAction(true);
        }
        setAction(false);
      } else if (response.status === 422) {
        setError(response.data);
      } else {
        Message.errorMsg(t("error_message"));
      }
    });
  };

  const deletesuperadmin = (e, id, name) => {
    ConfirmPopup.deleteSuperadminPopup(name).then((response) => {
      if (response.isConfirmed) {
        Request.deleteRequest(ApiUrl.deleteSuperadmin + id).then((response) => {
          if (response.status === 200) {
            setAction(true);
            Message.successMsg(response.data.success);
          } else if (response.status === 404) {
            Message.errorMsg(response.data.error);
          } else {
            Message.errorMsg(t("error_message"));
          }
        });
      }
    });
  };

  const inviteSuperadmin = (e) => {
    e.preventDefault(e);
    setBtnLoading(true);
    var data = {
      email: email,
    };
    Request.postRequest(ApiUrl.inviteSuperadmin, data).then((response) => {
      setBtnLoading(false);
      if (response.status === 201) {
        setEmail("");
        setError([]);
        Message.successMsg(response.data.success);
      } else if (response.status === 422) {
        setError(response.data);
      } else {
        Message.errorMsg(t("error_message"));
      }
    });
  };

  return (
    <>
      <p className="partial-title mb-5">{t("superadmins_list_page_title")}</p>
      <div className="add-item pt-4 pb-2 px-4 mb-5">
        <form onSubmit={(e) => inviteSuperadmin(e)}>
          <div className="row">
            <label>{t("invite_new_superadmin")}</label>
            <div className="form-block col-12 col-md-5 col-xxl-3">
              <input
                placeholder={t("superadmin_invite_placeholder")}
                className={"form-input" + (error?.email ? " input-error" : "")}
                name="email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              {error?.email && <ValidationMessage message={error?.email[0]} />}
            </div>
            <div className="col-12 col-sm-6 col-md-4 pb-3">
              {btnLoading ? (
                <button type="button" className="button-blue has-icon">
                  <i className="icon spinner-icon me-2" />
                  {t("superadmin_inviting")}
                </button>
              ) : (
                <button type="submit" className="button-blue has-icon">
                  {t("superadmin_invite")}
                </button>
              )}
            </div>
          </div>
        </form>
      </div>
      <div className="table-list mb-5">
        <table role="table" className="w-100">
          <thead>
            <tr role="row">
              <th role="columnheader" className="text-left">
                {t("superadmin_list_name")}
              </th>
              <th role="columnheader" className="text-center">
                {t("superadmin_list_lastname")}
              </th>
              <th role="columnheader" className="text-center">
                {t("superadmin_list_email")}
              </th>
              <th role="columnheader" className="text-center">
                {t("superadmin_list_phone")}
              </th>
              <th role="columnheader" className="text-center">
                {t("superadmin_list_action")}
              </th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td className="text-center" colSpan="5">
                  <Loading />
                </td>
              </tr>
            ) : (
              superAdmins.map((superadmin, index) => (
                <tr key={superadmin.id} role="row">
                  <td role="cell" data-label={t("superadmin_list_name")}>
                    {superadmin.first_name}
                  </td>
                  <td
                    role="cell"
                    data-label={t("superadmin_list_lastname")}
                    className="text-center"
                  >
                    {superadmin.last_name}
                  </td>
                  <td
                    role="cell"
                    data-label={t("superadmin_list_email")}
                    className="text-center"
                  >
                    {superadmin.email}
                  </td>
                  <td
                    role="cell"
                    data-label={t("superadmin_list_phone")}
                    className="text-center"
                  >
                    {superadmin.phone}
                  </td>
                  <td
                    role="cell"
                    data-label={t("superadmin_list_action")}
                    className="text-center"
                  >
                    <div className="row justify-content-around mr-auto ml-auto action-icons">
                      <div className="delete">
                        <button
                          type="submit"
                          className="button-unstyled-icon"
                          onClick={(e) =>
                            deletesuperadmin(
                              e,
                              superadmin.id,
                              superadmin.first_name + " " + superadmin.last_name
                            )
                          }
                        >
                          <i className="icon delete-icon" />
                        </button>
                      </div>
                    </div>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
        <ListResponse
          totalItems={pagination && pagination.total}
          loading={loading}
          searched={false}
        />
      </div>
      <CustomPagination
        data={pagination}
        setActivePage={(e) => setActivePage(e)}
      />
    </>
  );
};

export default SuperAdminsList;
