import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useUserdata } from "../../../../store/UserData";
import SearchFilter from "../../../../helpers/SearchFilter";
import ListResponse from "../../../../helpers/ListResponse";
import Loading from "../../../../helpers/Loading";
import CustomPagination from "../../../../helpers/CustomPagination";

import * as Request from "../../../../api/Request";
import * as ApiUrl from "../../../../api/ApiUrl";
import * as Message from "../../../../helpers/Messages";
import * as ConfirmPopup from "../../../../helpers/ConfirmPopup";
import { FileIcon, defaultStyles } from "react-file-icon";
import moment from "moment";

const Trash = () => {
  const { t } = useTranslation("");
  const { siteId } = useParams();
  const userData = useUserdata((state) => state.userData);
  const navigate = useNavigate();

  const [deletedDocuments, setDeletedDocuments] = useState([]);
  const [activePage, setActivePage] = useState("");
  const [action, setAction] = useState(false);
  const [pagination, setPagination] = useState([]);
  const [loading, setLoading] = useState(false);
  const [deleteAllLoading, setDeleteAllLoading] = useState(false);
  const [restoreAllLoading, setRestoreAllLoading] = useState(false);

  const deleteAllDocumentsForever = () => {
    setDeleteAllLoading(true);
    Request.deleteRequest(ApiUrl.deleteAllDocumentsForever(siteId)).then(
      (response) => {
        setDeleteAllLoading(false);
        if (response.status === 200) {
          setAction(true);
          Message.successMsg(response.data.success);
        } else if (response.status === 404) {
          Message.errorMsg(response.data.error);
        } else {
          Message.errorMsg(t("error_message"));
        }
      }
    );
  };

  const restoreAllDocuments = () => {
    setRestoreAllLoading(true);
    let request = ApiUrl.restoreAll(siteId);
    Request.getRequest(request).then((response) => {
      setRestoreAllLoading(false);
      if (response.status === 200) {
        Message.successMsg(response.data.success);
        setAction(true);
        setLoading(false);
      } else if (response.status === 404) {
        Message.errorMsg(response.data.error);
      } else {
        Message.errorMsg(t("error_message"));
      }
    });
  };

  const deleteDocument = (e, id, name) => {
    ConfirmPopup.deleteDocumentForeverPopup(name).then((response) => {
      if (response.isConfirmed) {
        Request.deleteRequest(ApiUrl.deleteDocumentForever + id).then(
          (response) => {
            if (response.status === 200) {
              setAction(true);
              Message.successMsg(response.data.success);
            } else if (response.status === 404) {
              Message.errorMsg(response.data.error);
            } else {
              Message.errorMsg(t("error_message"));
            }
          }
        );
      }
    });
  };

  const restoreSelected = (e, id, name) => {
    ConfirmPopup.restorePopup(name).then((response) => {
      if (response.isConfirmed) {
        Request.getRequest(ApiUrl.restoreDocument(siteId) + id).then(
          (response) => {
            if (response.status === 200) {
              setAction(true);
              Message.successMsg(response.data.success);
            } else if (response.status === 404) {
              Message.errorMsg(response.data.error);
            } else {
              Message.errorMsg(t("error_message"));
            }
          }
        );
      }
    });
  };

  useEffect(() => {
    deletedDocumentsList();
  }, [action, activePage]);

  const deletedDocumentsList = () => {
    let request = ApiUrl.deletedDocuments(siteId);

    var searchParams = {};

    if (activePage > 1) {
      searchParams = {
        page: activePage,
      };
    } else if (activePage > 1) {
      searchParams = {
        page: activePage,
      };
    }
    request = SearchFilter(searchParams, request);

    Request.getRequest(request).then((response) => {
      if (response.status === 200) {
        setDeletedDocuments(response.data.deleted_documents.data);
        setLoading(false);
        setAction(false);
        setPagination(response.data.deleted_documents);

        if (response.data.deleted_documents.data.length < 1 && activePage > 1) {
          setActivePage(activePage - 1);
          setAction(true);
        }
      } else if (response.status === 403) {
        setTimeout(() => {
          navigate(-1);
        }, 2000);
        Message.errorMsg(response.data.error);
      } else if (response.status === 404) {
        setTimeout(() => {
          navigate(-1);
        }, 2000);
        Message.errorMsg(response.data.error);
      } else {
        Message.errorMsg(t("error_message"));
      }
    });
  };

  return (
    <>
      <p className="partial-title mb-5">{t("site_recycle_bin_title")}</p>
      {(userData.role === "superadmin" || userData.role === "admin") && (
        <div className="d-md-flex justify-content-between mb-5">
          <div className="d-flex">
            {deleteAllLoading ? (
              <button type="button" className="button-blue has-icon me-3">
                <i className="icon spinner-icon me-2" />

                {t("site_recycle_bin_deleting_all_button")}
              </button>
            ) : (
              <button
                type="button"
                className="button-blue me-3"
                onClick={(e) => deleteAllDocumentsForever(e)}
              >
                {t("site_recycle_bin_delete_all_button")}
              </button>
            )}

            {restoreAllLoading ? (
              <button type="button" className="button-blue-border has-icon">
                <i className="icon spinner-icon me-2" />

                {t("site_recycle_bin_restoring_all_button")}
              </button>
            ) : (
              <button
                type="button"
                className="button-blue-border"
                onClick={(e) => restoreAllDocuments(e)}
              >
                {t("site_recycle_bin_restore_all_button")}
              </button>
            )}
          </div>
        </div>
      )}
      <div className="table-list-no-bg mb-5">
        <table role="table" className="w-100">
          <thead>
            <tr role="row">
              <th
                role="columnheader"
                className="text-left"
                style={{ width: "30%" }}
              >
                {t("site_recycle_bin_list_path")}
              </th>
              <th
                role="columnheader"
                className="text-left"
                style={{ width: "25%" }}
              >
                {t("site_recycle_bin_list_name")}
              </th>
              <th
                role="columnheader"
                className="text-left"
                style={{ width: "25%" }}
              >
                {t("site_recycle_bin_list_deleted")}
              </th>
              <th
                role="columnheader"
                className="text-center"
                style={{ width: "20%" }}
              >
                {t("site_recycle_bin_list_action")}
              </th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td className="text-center" colSpan="4">
                  <Loading />
                </td>
              </tr>
            ) : (
              deletedDocuments.map((document, index) => (
                <tr key={index} role="row">
                  <td role="cell" data-label={t("site_recycle_bin_list_path")}>
                    <div className="d-flex align-items-center gap-2">
                      <span style={{ maxWidth: "20px" }}>
                        <FileIcon
                          extension={document.path.split(".").pop()}
                          {...defaultStyles[document.path.split(".").pop()]}
                        />
                      </span>
                      {document.path.replace(/^\d+_/, "")}
                    </div>
                  </td>
                  <td role="cell" data-label={t("site_recycle_bin_list_name")}>
                    {document.name}
                  </td>
                  <td
                    role="cell"
                    data-label={t("site_recycle_bin_list_deleted")}
                  >
                    {moment(document.deleted_at).format("DD MMMM, YYYY HH:mm")}
                  </td>
                  <td
                    role="cell"
                    data-label={t("site_recycle_bin_list_action")}
                    className="text-center"
                  >
                    <div className="row justify-content-center mr-auto ml-auto action-icons">
                      <div className="restore">
                        <button
                          className="button-unstyled-icon"
                          onClick={(e) =>
                            restoreSelected(e, document.id, document.name)
                          }
                        >
                          <i className="icon restore-icon" />
                        </button>
                      </div>
                      <div className="delete">
                        <button
                          type="submit"
                          className="button-unstyled-icon"
                          onClick={(e) =>
                            deleteDocument(e, document.id, document.name)
                          }
                        >
                          <i className="icon delete-icon" />
                        </button>
                      </div>
                    </div>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
        <ListResponse
          totalItems={pagination && pagination.total}
          loading={loading}
          searched={false}
        />
      </div>
      <CustomPagination
        data={pagination}
        setActivePage={(e) => setActivePage(e)}
      />
    </>
  );
};

export default Trash;
