import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import SearchFilter from "../../../helpers/SearchFilter";
import ListResponse from "../../../helpers/ListResponse";
import ValidationMessage from "../../../helpers/ValidationMessage";
import Loading from "../../../helpers/Loading";
import CustomPagination from "../../../helpers/CustomPagination";

import * as Request from "../../../api/Request";
import * as ApiUrl from "../../../api/ApiUrl";
import * as Message from "../../../helpers/Messages";
import * as ConfirmPopup from "../../../helpers/ConfirmPopup";

const Users = () => {
  const { t } = useTranslation();

  const [members, setMembers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [term, setTerm] = useState("");
  const [email, setEmail] = useState("");
  const [error, setError] = useState({});
  const [btnLoading, setBtnLoading] = useState(false);
  const [searchBtnLoading, setSearchBtnLoading] = useState(false);
  const [searched, setSearched] = useState(false);
  const [activePage, setActivePage] = useState("");
  const [pagination, setPagination] = useState([]);

  useEffect(() => {
    listMembers();
  }, [activePage, searched]);

  const listMembers = (hasSearch) => {
    let request = ApiUrl.listMembers;

    var searchParams = {};

    if (hasSearch) {
      hasSearch.preventDefault();
      setSearchBtnLoading(true);
    }

    if (!searched && activePage > 1) {
      searchParams = {
        page: activePage,
      };
    } else if (searched && activePage <= 1) {
      searchParams = {
        term: term,
      };
    } else if (searched && activePage > 1) {
      searchParams = {
        term: term,
        page: activePage,
      };
    }
    request = SearchFilter(searchParams, request);

    Request.getRequest(request).then((response) => {
      setSearchBtnLoading(false);
      if (response.status === 200) {
        setMembers(response.data.members.data);
        setLoading(false);
        setError({});
        setPagination(response.data.members);
      } else if (response.status === 422) {
        setError(response.data);
      } else {
        Message.errorMsg(t("error_message"));
      }
    });
  };

  const deleteMember = (e, id, name) => {
    ConfirmPopup.deleteMemberPopup(name).then((response) => {
      if (response.isConfirmed) {
        Request.deleteRequest(ApiUrl.deleteMember + id).then((response) => {
          if (response.status === 200) {
            listMembers();
            Message.successMsg(response.data.success);
          } else if (response.status === 404) {
            Message.errorMsg(response.data.error);
          } else {
            Message.errorMsg(t("error_message"));
          }
        });
      }
    });
  };

  const inviteMember = (e) => {
    e.preventDefault(e);
    setBtnLoading(true);
    var data = {
      email: email,
    };
    Request.postRequest(ApiUrl.inviteMember, data).then((response) => {
      setBtnLoading(false);
      if (response.status === 201) {
        setEmail("");
        setError({});
        Message.successMsg(response.data.success);
      } else if (response.status === 422) {
        setError(response.data);
      } else {
        Message.errorMsg(t("error_message"));
      }
    });
  };

  return (
    <>
      <p className="partial-title mb-5">{t("user_list")}</p>
      <div className="add-item pt-4 pb-2 px-4 mb-5">
        <form onSubmit={(e) => inviteMember(e)}>
          <div className="row">
            <label>{t("invite_new_user")}</label>
            <div className="form-block col-12 col-md-5 col-xxl-3">
              <input
                placeholder={t("user_invite_placeholder")}
                className={"form-input" + (error?.email ? " input-error" : "")}
                name="email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              {error?.email && <ValidationMessage message={error?.email[0]} />}
            </div>
            <div className="col-12 col-sm-6 col-md-4 pb-3">
              {btnLoading ? (
                <button type="button" className="button-blue has-icon">
                  <i className="icon spinner-icon me-2" />
                  {t("user_inviting")}
                </button>
              ) : (
                <button type="submit" className="button-blue has-icon">
                  {t("user_invite")}
                </button>
              )}
            </div>
          </div>
        </form>
      </div>
      <div className="search-item px-md-4 mb-5">
        <form onSubmit={(e) => listMembers(e)}>
          <div className="row">
            <label>{t("user_list_search_label")}</label>
            <div className="form-block col-12 col-md-5 col-xxl-3">
              <input
                placeholder={t("user_list_search_name")}
                className="form-input"
                name="term"
                type="text"
                onChange={(e) => setTerm(e.target.value)}
              />
              {error?.term && <ValidationMessage message={error?.term[0]} />}
            </div>
            <div className="form-block col-12 col-sm-6 col-md-4 pb-3">
              {searchBtnLoading ? (
                <button type="button" className="button-blue has-icon">
                  <i className="icon spinner-icon me-2" />
                  {t("user_list_searching")}
                </button>
              ) : (
                <button
                  type="submit"
                  className="button-blue has-icon"
                  onClick={() => {
                    setSearched(true);
                    setActivePage("1");
                  }}
                >
                  <i className="icon search-icon me-2" />
                  {t("user_list_search")}
                </button>
              )}
            </div>
          </div>
        </form>
      </div>
      <div className="table-list mb-5">
        <table role="table" className="w-100">
          <thead>
            <tr role="row">
              <th role="columnheader" className="text-left">
                {t("user_list_name")}
              </th>
              <th role="columnheader" className="text-center">
                {t("user_list_lastname")}
              </th>
              <th role="columnheader" className="text-center">
                {t("user_list_email")}
              </th>
              <th role="columnheader" className="text-center">
                {t("user_list_phone")}
              </th>
              <th role="columnheader" className="text-center">
                {t("user_list_status")}
              </th>
              <th role="columnheader" className="text-center">
                {t("user_list_action")}
              </th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td className="text-center" colSpan="6">
                  <Loading />
                </td>
              </tr>
            ) : (
              members.map((member) => (
                <tr key={member.id} role="row">
                  <td role="cell" data-label={t("user_list_name")}>
                    {member.first_name}
                  </td>
                  <td
                    role="cell"
                    data-label={t("user_list_lastname")}
                    className="text-center"
                  >
                    {member.last_name}
                  </td>
                  <td
                    role="cell"
                    data-label={t("user_list_email")}
                    className="text-center"
                  >
                    {member.email}
                  </td>
                  <td
                    role="cell"
                    data-label={t("user_list_phone")}
                    className="text-center"
                  >
                    {member.phone}
                  </td>
                  <td
                    role="cell"
                    data-label={t("user_list_status")}
                    className="text-center status"
                  >
                    <span
                      className={
                        member.status === "Active" || member.status === "Aktiv"
                          ? "active"
                          : "inactive"
                      }
                    >
                      <i className="dot me-2 mx-1"></i>
                      {member.status}
                    </span>
                  </td>
                  <td
                    role="cell"
                    data-label={t("user_list_action")}
                    className="text-center"
                  >
                    <div className="row justify-content-around mr-auto ml-auto action-icons">
                      <div className="delete">
                        <button
                          type="submit"
                          className="button-unstyled-icon"
                          onClick={(e) =>
                            deleteMember(
                              e,
                              member.id,
                              member.first_name + " " + member.last_name
                            )
                          }
                        >
                          <i className="icon delete-icon" />
                        </button>
                      </div>
                    </div>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
        <ListResponse
          totalItems={pagination && pagination.total}
          loading={loading}
          searched={searched}
        />
      </div>
      <CustomPagination
        data={pagination}
        setActivePage={(e) => {
          setActivePage(e);
          Object.keys(error).length > 0 && setSearched(false);
        }}
      />
    </>
  );
};

export default Users;
