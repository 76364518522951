import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useUserdata } from "../../../store/UserData";
import { useSidebarStatus } from "../../../store/SidebarStatus";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { get, map } from "lodash";

//i18n
import i18n from "../../../i18n/i18n";
import languages from "../../../i18n/Languages";
import { Language } from "../../../store/Language";

import * as Path from "../../../routes/GeneralPaths";
import * as Request from "../../../api/Request";
import * as ApiUrl from "../../../api/ApiUrl";
import * as AppConfig from "../../../helpers/AppConfig";

import LanguageDropdown from "../Common/LanguageDropdown";
import Logo from "../../../assets/images/logo/logo.png";
import LogoIcon from "../../../assets/images/logo/logo-icon.svg";

const Header = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const userData = useUserdata((state) => state.userData);
  const addStatus = useSidebarStatus((state) => state.addStatus);
  const language = Language((state) => state.addLanguage);

  const location = useLocation();

  const [sidebarStatus, setSidebarStatus] = useState(false);
  const [selectedLang, setSelectedLang] = useState("");

  const logout = () => {
    Request.postRequest(ApiUrl.logout).then((response) => {
      if (response.status === 200) {
        AppConfig.deleteAccessToken();
        window.location.href = Path.homepage;
      }
    });
  };

  useEffect(() => {
    setSidebarStatus(false);
    addStatus(false);
  }, [location]);

  useEffect(() => {
    const currentLanguage = localStorage.getItem("i18nextLng");
    setSelectedLang(currentLanguage);
  }, []);

  const collapseSidebar = () => {
    setSidebarStatus(!sidebarStatus);
  };

  const changeLanguageAction = (lang) => {
    AppConfig.setLanguage(lang);
    //set language as i18n
    i18n.changeLanguage(lang);
    localStorage.setItem("i18nextLng", lang);
    setSelectedLang(lang);
    language(lang);
    window.location.reload();
  };

  return (
    <>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            <div className="navbar-brand-box">
              <div className="initals">
                <span>
                  {`${
                    userData.first_name ? userData.first_name.charAt(0) : ""
                  }${userData.last_name ? userData.last_name.charAt(0) : ""}`}
                </span>
              </div>
              <div className="user-data">
                <span className="user-role">
                  {userData.role_trans ? userData.role_trans : ""}
                </span>
                <span className="user-full-name">
                  {(userData.first_name && userData.first_name.length > 20) ||
                  (userData.first_name && userData.last_name.length > 20)
                    ? userData.first_name
                      ? userData.first_name
                      : userData.last_name
                    : `${userData.first_name ? userData.first_name : ""} ${
                        userData.last_name ? userData.last_name : ""
                      }`}
                </span>
              </div>
            </div>
          </div>

          <div className="d-flex main-navbar">
            <a
              href={Path.homepage}
              target="_blank"
              className="logo"
              rel="noreferrer"
            >
              <img src={Logo} alt="Logo" height="50px" />
            </a>
            <a
              href={Path.homepage}
              target="_blank"
              className="logo-icon"
              rel="noreferrer"
            >
              <img src={LogoIcon} alt="Logo" height="50px" />
            </a>
            <div className="d-flex align-items-center">
              <LanguageDropdown />
              <button
                type="button"
                className="button-white-rounded hamburger-button me-3"
                onClick={() => {
                  collapseSidebar();
                  addStatus(!sidebarStatus);
                }}
              >
                <i className="icon hamburger-icon" />
              </button>
              <Link
                to={Path.profile}
                className="button-white-rounded password-btn me-3"
              >
                <i className="icon profile-icon" />
              </Link>
              {userData.role !== "superadmin" ? (
                <button
                  type="button"
                  className="button-unstyled-icon tutorial me-3"
                  // style={{ padding: "0 25px" }}
                  onClick={() => navigate(Path.tutorial)}
                >
                  <i className="icon video-icon me-1" />
                  {t("tutorial_button")}
                </button>
              ) : (
                ""
              )}
              <button
                type="button"
                className="button-gray logout-btn"
                onClick={() => logout()}
              >
                {t("navbar_logout")}
              </button>

              <div className="header-user-dropdown">
                <button
                  type="button"
                  className="dropdown-toggle"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <div className="initals">
                    <span>
                      {`${
                        userData.first_name ? userData.first_name.charAt(0) : ""
                      }${
                        userData.last_name ? userData.last_name.charAt(0) : ""
                      }`}
                    </span>
                  </div>
                </button>
                <ul className="dropdown-menu">
                  <li>
                    {`${userData.first_name ? userData.first_name : ""} ${
                      userData.last_name ? userData.last_name : ""
                    }`}
                  </li>
                  <li>
                    <Link to={Path.profile}>{t("navbar_my_profile")}</Link>
                  </li>
                  {userData.role !== "superadmin" ? (
                    <li>
                      <Link to={Path.tutorial}>{t("tutorial_button")}</Link>
                    </li>
                  ) : (
                    ""
                  )}
                  {map(
                    Object.keys(languages),
                    (key, index) =>
                      selectedLang !== key && (
                        <li
                          key={index}
                          onClick={() => changeLanguageAction(key)}
                        >
                          <div key={key} className="notify-item none">
                            <img
                              src={get(languages, `${key}.flag`)}
                              alt="Flag"
                              className="me-1"
                              height="12"
                            />
                            <span className="align-middle">
                              {get(languages, `${key}.label`)}
                            </span>
                          </div>
                        </li>
                      )
                  )}
                  <li>
                    <hr className="dropdown-divider" />
                  </li>
                  <li>
                    <button type="button" onClick={() => logout()}>
                      {t("navbar_logout")}
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
