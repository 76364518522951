// Api Base URL
import { apiBaseUrl } from "../helpers/AppConfig";

// Authentication
export const login = apiBaseUrl + "login";
export const logout = apiBaseUrl + "logout";
export const resetLink = apiBaseUrl + "send-reset-link";
export const resetPassword = apiBaseUrl + "reset-password/";
export const storePassword = apiBaseUrl + "accept-password-change/";
export const acceptPasswordChange = apiBaseUrl + "accept-password-change/";
export const acceptInvitation = apiBaseUrl + "accept-invitation/";
export const completeRegistration = apiBaseUrl + "complete-registration/";

// User
export const profileData = apiBaseUrl + "profile-data";
export const myProfile = apiBaseUrl + "my-profile";
export const updateProfile = apiBaseUrl + "update/profile";
export const changePassword = apiBaseUrl + "change-password";

// Company & Users
export const inviteCompany = apiBaseUrl + "company/invite";
export const inviteMember = apiBaseUrl + "invite/member";
export const inviteSuperadmin = apiBaseUrl + "invite/super-admin";
export const listMembers = apiBaseUrl + "company/members";
export const listCompanies = apiBaseUrl + "company/list";
export const listSuperadmins = apiBaseUrl + "user/list";
export const deleteCompany = apiBaseUrl + "company/delete/";
export const deleteSuperadmin = apiBaseUrl + "superadmin/delete/";
export const deleteMember = apiBaseUrl + "member/delete/";

// Sites
export const storeSite = apiBaseUrl + "site/store";
export const listOfSites = apiBaseUrl + "site/list";
export const membersExpectMe = apiBaseUrl + "company/members-expect-me";
export const updateSite = (siteId) => apiBaseUrl + `site/update/${siteId}`;
export const deleteSite = apiBaseUrl + "site/delete/";
export const getSiteName = (siteId) => apiBaseUrl + `site/${siteId}/name`;

// Documents
export const storeDocument = apiBaseUrl + "site/document/store";
export const storeFolder = apiBaseUrl + "site/folder/store";
export const updateFolder = (folderID) =>
  apiBaseUrl + `site/folder/update/${folderID}`;
export const documentDetails = (siteId) =>
  apiBaseUrl + `site/${siteId}/details`;
export const documentFolders = (siteId) =>
  apiBaseUrl + `site/${siteId}/folders`;
export const downloadDoc = apiBaseUrl + "site/document/download";
export const deleteFolder = (siteId) =>
  apiBaseUrl + `site/${siteId}/folder/delete/`;
export const getFileDetails = (fileId) =>
  apiBaseUrl + `site/document/details/${fileId}`;
export const updateFile = (fileId) =>
  apiBaseUrl + `site/document/update/${fileId}`;
export const deleteDocument = apiBaseUrl + "site/document/delete/";

// Classifications
export const classificationsList = (siteId) =>
  apiBaseUrl + `site/${siteId}/classifications`;
export const addClassification = apiBaseUrl + "site/classification/store";
export const updateClassification = (classificationId) =>
  apiBaseUrl + `site/classification/update/${classificationId}`;
export const classificationDelete = (siteId) =>
  apiBaseUrl + `site/${siteId}/classification/delete/`;

// Classification Items
export const itemsList = (classificationId) =>
  apiBaseUrl + `site/classification/${classificationId}/items`;
export const addItem = apiBaseUrl + "site/classification/item/store";
export const updateItem = (itemId) =>
  apiBaseUrl + `site/classification/item/update/${itemId}`;
export const itemDelete = (siteId, itemId) =>
  apiBaseUrl + `site/${siteId}/classification/item/delete/${itemId}`;

// Members
export const siteMembers = (siteId) => apiBaseUrl + `site/${siteId}/members`;

// Tags
export const tagsList = (siteId) => apiBaseUrl + `site/${siteId}/tags`;
export const tagStore = apiBaseUrl + "site/tag/store";
export const tagUpdate = (tagId) => apiBaseUrl + `site/tag/update/${tagId}`;
export const tagDelete = (tagId) => apiBaseUrl + `site/tag/delete/${tagId}`;

// Trash
export const deletedDocuments = (siteId) =>
  apiBaseUrl + `site/${siteId}/deleted/documents`;
export const restoreAll = (siteId) =>
  apiBaseUrl + `site/${siteId}/restore/documents`;
export const restoreDocument = (siteId) =>
  apiBaseUrl + `site/${siteId}/restore/document/`;
export const deleteDocumentForever =
  apiBaseUrl + "site/document/delete-document-forever/";
export const deleteAllDocumentsForever = (siteId) =>
  apiBaseUrl + `site/${siteId}/delete-all-documents-forever`;

// Contact us
export const contactUs = apiBaseUrl + "contact";

// Tutorial
export const tutorial = apiBaseUrl + "tutorial";
