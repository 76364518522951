import { useTranslation } from "react-i18next";
import Pagination from "react-js-pagination";

const CustomPagination = ({ data, setActivePage }) => {
  const { t } = useTranslation("");

  return data.total > 10 ? (
    <div className="pagination-block mt-5">
      <Pagination
        activePage={data.current_page}
        itemsCountPerPage={data.per_page}
        totalItemsCount={data.total}
        pageRangeDisplayed={5}
        onChange={(e) => setActivePage(e)}
        itemClass="page-item"
        linkClass="page-link"
        hideFirstLastPages={true}
        itemClassPrev="prev-item"
        itemClassNext="next-item"
        prevPageText={<i className="icon left-pagination-icon" />}
        nextPageText={<i className="icon right-pagination-icon" />}
      />
      <div className="pagination-results">
        {`${t("pagination_page_number")}: ${data.current_page} - ${
          data.last_page
        }`}
      </div>
    </div>
  ) : (
    ""
  );
};

export default CustomPagination;
