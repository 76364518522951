import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useState } from "react";
import { useUserdata } from "../../../../store/UserData";
import Items from "./items/Items";
import ClassificationsSidebar from "./ClassificationsSidebar";

const Classifications = () => {
  const { t } = useTranslation();
  const { classificationId } = useParams();
  const userData = useUserdata((state) => state.userData);

  // Add new item modal
  const [addItemModal, setAddItemModal] = useState(false);

  // Render items componet
  const [renderItems, setRenderItems] = useState(false);

  // Check if user clicked item
  const [item, setItem] = useState(false);

  return (
    <>
      <p className="partial-title mb-5">
        {t("classifications_list_page_title")}
      </p>

      <div
        className={`w-100 sidebar-filter gap${
          !classificationId ? " pt-0" : ""
        }${userData.role === "member" ? " pt-0" : ""}`}
      >
        <ClassificationsSidebar
          hasNoClassificationId={classificationId ? false : true}
          renderItems={(e) => setRenderItems(e)}
          clickedItem={(e) => setItem(e)}
        />
        {(userData.role === "superadmin" || userData.role === "admin") &&
          classificationId &&
          renderItems && (
            <div className="add-item-row">
              <button
                type="button"
                className="button-blue"
                onClick={() => setAddItemModal(true)}
              >
                {t("item_add_new_item_button")}
              </button>
            </div>
          )}
        {classificationId && renderItems ? (
          <Items
            showAddItemModal={addItemModal}
            clickedItem={item}
            closeItemModal={(e) => setAddItemModal(e)}
            setClickedItem={() => setItem(false)}
          />
        ) : (
          <div className="table-list-results no-classification-selected">
            {t("classifications_list_no_classification_selected")}
          </div>
        )}
      </div>
    </>
  );
};

export default Classifications;
