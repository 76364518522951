import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useParams } from "react-router-dom";
import { useUserdata } from "../../../store/UserData";
import SimpleBar from "simplebar-react";

import * as Path from "../../../routes/GeneralPaths";
import * as ApiUrl from "../../../api/ApiUrl";
import * as Request from "../../../api/Request";
import * as AppConfig from "../../../helpers/AppConfig";
import { SidebarSite } from "../../../store/SidebarSite";

const SidebarContent = ({ siteMenu }) => {
  const { t } = useTranslation();
  const { siteId } = useParams();

  const ref = useRef();
  const sidebarRef = useRef();

  const userData = useUserdata((state) => state.userData);

  const siteName = SidebarSite((state) => state.siteName);
  const addSiteName = SidebarSite((state) => state.addSiteName);

  useEffect(() => {
    if (Object.keys(userData).length > 0 && siteMenu) {
      const activeA = ref.current.querySelector(".active");
      if (activeA) {
        const activeLi = activeA.parentElement;
        const activeUl = activeLi.parentElement;
        const activeDiv = activeUl.parentElement;
        activeDiv.classList.add("show");
        activeDiv.parentElement.classList.add("active-item");
        const btn = activeDiv.parentElement.querySelector("button");
        btn.classList.remove("collapsed");
      }
    }
  }, [userData, siteMenu]);

  if (Object.keys(userData).length > 0) {
    if (userData.role === AppConfig.authRole()) {
      var superadmin = userData.role === "superadmin";
      var admin = userData.role === "admin";
      var member = userData.role === "member";
    } else {
      Request.postRequest(ApiUrl.logout).then((response) => {
        if (response.status === 200) {
          AppConfig.deleteAccessToken();
          window.location.href = Path.homepage;
        } else {
          AppConfig.deleteAccessToken();
          window.location.href = Path.homepage;
        }
      });
    }
  }

  useEffect(() => {
    let sessionSiteId = sessionStorage.getItem("siteId");

    if (
      (siteId && !sessionSiteId) ||
      (siteId === sessionSiteId && siteName === t("site_menu"))
    ) {
      Request.getRequest(ApiUrl.getSiteName(siteId)).then((response) => {
        if (response.status === 200) {
          addSiteName(response.data.name);
        } else {
          addSiteName(t("site_menu"));
        }
      });

      sessionStorage.setItem("siteId", siteId);
    } else if (siteId !== sessionSiteId) {
      sessionStorage.removeItem("siteId");
    }
  }, []);

  return (
    <>
      <SimpleBar className="h-100" ref={sidebarRef}>
        <div id="sidebar-menu" ref={ref}>
          <div className="menu-item">
            {superadmin && (
              <NavLink to={Path.superAdminList}>
                <i className="icon admin-icon" />
                <span>{t("sidebar_admins")}</span>
              </NavLink>
            )}
            {superadmin && (
              <NavLink to={Path.companiesList}>
                <i className="icon company-icon" />
                <span>{t("sidebar_companies")}</span>
              </NavLink>
            )}
            {admin && (
              <NavLink to={Path.usersList}>
                <i className="icon users-icon" />
                <span>{t("sidebar_users")}</span>
              </NavLink>
            )}
            {(admin || member) && (
              <NavLink to={Path.sitesList}>
                <i className="icon site-icon" />
                <span>{t("sidebar_sites")}</span>
              </NavLink>
            )}
          </div>
          {siteMenu && (
            <div className="menu-item">
              <button
                className="collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#catalog"
                aria-expanded="false"
                aria-controls="catalog"
              >
                <i className="icon site-icon" />
                <span>{siteName}</span>
                <i className="icon sidebar-arrow" />
              </button>
              <div
                id="catalog"
                className="collapse"
                aria-labelledby="catalog"
                data-bs-parent="#sidebar-menu"
              >
                <ul className="sub-menu list-unstyled">
                  <>
                    <li>
                      <NavLink to={Path.siteDocuments(siteId)}>
                        {t("site_menu_documents")}
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to={Path.siteClassifications(siteId)}>
                        {t("site_menu_classifications")}
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to={Path.siteMembersList(siteId)}>
                        {t("site_menu_members")}
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to={Path.siteTags(siteId)}>
                        {t("site_menu_tags")}
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to={Path.siteTrash(siteId)}>
                        {t("site_menu_trash")}
                      </NavLink>
                    </li>
                  </>
                </ul>
              </div>
            </div>
          )}
        </div>
      </SimpleBar>
      <div className="storage-bar">
        <div className="title mb-2 d-flex">
          <i className="icon storage-icon me-2" />
          {t("site_menu_storage")}
        </div>
        <div className="progress">
          <div
            className="progress-value"
            style={{
              "--percentage-complete": `${
                (userData.company_storage / 15) * 100
              }%`,
            }}
          ></div>
        </div>
        <p className="percentage-text mt-1">
          {userData.company_storage ? userData.company_storage.toFixed(2) : "0"}
          {t("site_menu_percentage_left")}
        </p>
      </div>
    </>
  );
};

export default SidebarContent;
