import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import ValidationMessage from "../../../../../helpers/ValidationMessage";
import Select from "react-select";
import Loading from "../../../../../helpers/Loading";

import * as Request from "../../../../../api/Request";
import * as ApiUrl from "../../../../../api/ApiUrl";
import * as Message from "../../../../../helpers/Messages";

const EditFile = ({ onShowModal, setFileVisible, setAction, fileId }) => {
  const { t } = useTranslation("");
  const { siteId, parentId } = useParams();

  const [name, setName] = useState("");
  const [path, setPath] = useState(null);
  const [detailsList, setDetailsList] = useState([]);
  const [items, setItems] = useState({});
  const [tagsList, setTagsList] = useState([]);
  const [tags, setTags] = useState([]);
  const [loading, setLoading] = useState(true);
  const [btnLoading, setBtnLoading] = useState(false);
  const [validations, setValidations] = useState([]);

  const getFileDetails = () => {
    Request.getRequest(ApiUrl.getFileDetails(fileId)).then((response) => {
      if (response.status === 200) {
        setTagsList(response.data.alltags);
        setDetailsList(response.data.classifications);

        setName(response.data.document.name);
        setPath(response.data.document.path);

        let selectedTags = [];
        response.data.document.tags.forEach((tag) => {
          selectedTags.push({ label: tag.name, value: tag.id });
        });
        setTags(selectedTags);

        let selectedItems = {};
        response.data.document.items.forEach((item) => {
          selectedItems[`item[${item.classification_id}]`] = {
            label: item.name,
            value: item.id,
          };
        });

        setLoading(false);
        setItems(selectedItems);
      } else if (response.status === 403) {
        Message.errorMsg(response.data.error);
        onClose();
      } else if (response.status === 404) {
        Message.errorMsg(response.data.error);
        onClose();
      } else {
        Message.errorMsg(t("error_message"));
        onClose();
      }
    });
  };

  useEffect(() => getFileDetails(), []);

  const onClose = () => {
    setName("");
    setPath(null);
    setTags([]);
    setItems({});
    setDetailsList([]);
    setTagsList([]);
    setFileVisible(false);
    setValidations([]);
  };

  const updateFile = (e) => {
    e.preventDefault();
    setBtnLoading(true);

    const formData = new FormData();
    formData.append("name", name);
    if (path?.name) {
      formData.append("path", path);
    }
    tags.forEach((tag, index) => {
      formData.append("tags[" + index + "]", tag.value);
    });
    Object.keys(items).forEach((value, index) => {
      if (items[value] !== "") {
        formData.append(`items[${index}]`, items[value].value);
      }
    });
    if (parentId) {
      formData.append("folder_id", parentId);
    }
    formData.append("site_id", siteId);
    formData.append("_method", "PATCH");

    Request.postRequestExport(ApiUrl.updateFile(fileId), formData).then(
      (response) => {
        setBtnLoading(false);
        if (response.status === 200) {
          Message.successMsg(response.data.success);
          onClose();
          setAction(true);
          setValidations([]);
        } else if (response.status === 403) {
          Message.errorMsg(response.data.error);
        } else if (response.status === 404) {
          Message.errorMsg(response.data.error);
        } else if (response.status === 422) {
          setValidations(response.data);
        } else {
          Message.errorMsg(t("error_message"));
        }
      }
    );
  };

  return (
    <Modal isOpen={onShowModal} toggle={(e) => onClose(e)} size="lg" centered>
      <ModalHeader
        className="text-dark font-size-15"
        toggle={(e) => onClose(e)}
        tag="span"
      >
        {t("edit_document_popup_title")}
      </ModalHeader>
      <form onSubmit={(e) => updateFile(e)} className="text-center">
        <ModalBody>
          <div className="row">
            <div className="form-block col-12 col-md-6">
              <label className="d-flex text-left">
                {t("edit_document_file")}
              </label>
              <div className="upload-file-box">
                <div
                  className={
                    "form-input" + (validations.path ? " input-error" : "")
                  }
                >
                  {path === null ? (
                    <>
                      <input
                        className="upload-input"
                        onChange={(e) => setPath(e.target.files[0])}
                        name="path"
                        type="file"
                      />
                      <div className="upload-input-placeholder">
                        <span>{t("edit_document_popup_placeholder")}</span>
                        <i className="icon upload-icon" />
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="upload-input-placeholder">
                        <span>{path.name ? path.name : path}</span>
                        <i
                          className="icon close-red-icon"
                          onClick={() => setPath(null)}
                        />
                      </div>
                    </>
                  )}
                </div>
              </div>
              {validations?.path && (
                <ValidationMessage message={validations?.path} />
              )}
            </div>
            <div className="form-block col-12 col-md-6">
              <label className="d-flex text-left">
                {t("edit_document_custom_name")}
              </label>
              <input
                type="text"
                name="name"
                className={
                  "form-input" + (validations?.name ? " input-error" : "")
                }
                onChange={(e) => setName(e.target.value)}
                value={name}
                placeholder={t("edit_document_name_popup_placeholder")}
              />
              {validations?.name && (
                <ValidationMessage message={validations?.name} />
              )}
            </div>
          </div>
          {loading ? (
            <div className="text-center">
              <Loading />
            </div>
          ) : (
            <div className="dropdown-file">
              {detailsList.map((item) => (
                <div className="form-block" key={item.id}>
                  <label className="d-flex text-left">{item.name}</label>
                  <Select
                    className={
                      "form-react-select" +
                      (validations.items ? " input-error" : "")
                    }
                    classNamePrefix="select_input"
                    name="classification"
                    isClearable={true}
                    placeholder={t("edit_document_choose_items")}
                    onChange={(e) =>
                      setItems({
                        ...items,
                        [`item[${item.id}]`]: e
                          ? { label: e.label, value: e.value }
                          : "",
                      })
                    }
                    isSearchable={true}
                    value={items[`item[${item.id}]`]}
                    options={item.items.map((items) => ({
                      value: items.id,
                      label: items.name,
                    }))}
                  />
                  {validations.items && (
                    <ValidationMessage message={validations.items[0]} />
                  )}
                </div>
              ))}
            </div>
          )}
          <div className="form-block row mb-0">
            <label className="d-flex text-left">
              {t("edit_documents_tags")}
            </label>
            <Select
              className={
                "form-react-select" + (validations.tags ? " input-error" : "")
              }
              isMulti
              classNamePrefix="select_input"
              name="tags"
              isClearable={true}
              placeholder={t("edit_document_choose_tags")}
              onChange={(e) => (e ? setTags(e) : setTags([]))}
              value={tags}
              isSearchable={true}
              options={tagsList.map((tag) => ({
                value: tag.id,
                label: tag.name,
              }))}
            />
            {validations.tags && (
              <ValidationMessage message={validations.tags[0]} />
            )}
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="col-12 d-flex m-0 p-0">
            <div className="col-6 pe-2 text-end">
              {btnLoading ? (
                <button className="button-blue has-icon" type="button">
                  <i className="icon spinner-icon" />
                  {t("edit_saving_button")}
                </button>
              ) : (
                <button type="submit" className="button-blue">
                  {t("edit_save_button")}
                </button>
              )}
            </div>
            <div className="col-6 ps-2 text-start">
              <button
                className="button-blue-border"
                type="button"
                onClick={() => onClose()}
              >
                {t("edit_document_cancel_button")}
              </button>
            </div>
          </div>
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default EditFile;
