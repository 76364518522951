import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import * as Request from "../../../api/Request";
import * as ApiUrl from "../../../api/ApiUrl";
import * as Message from "../../../helpers/Messages";

const Tutorial = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [videoUrl, setVideoUrl] = useState("");

  useEffect(() => {
    getTutorial();
  }, []);

  const getTutorial = () => {
    Request.getRequest(ApiUrl.tutorial).then((response) => {
      if (response.status === 200) {
        setVideoUrl(response.data.signedUrl);
      } else if (response.status !== 401 && response.status !== 200) {
        response.data.error
          ? Message.errorMsg(response.data.error)
          : Message.errorMsg(t("error_message"));

        navigate(-1);
      }
    });
  };

  return (
    <>
      {videoUrl && (
        <video controls className="video-tutorial">
          <source src={videoUrl} type="video/mp4" />
        </video>
      )}
    </>
  );
};

export default Tutorial;
