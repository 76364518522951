import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useUserdata } from "../../../../store/UserData";
import SearchFilter from "../../../../helpers/SearchFilter";
import ListResponse from "../../../../helpers/ListResponse";
import ValidationMessage from "../../../../helpers/ValidationMessage";
import Loading from "../../../../helpers/Loading";
import CustomPagination from "../../../../helpers/CustomPagination";
import EditTag from "./EditTag";

import * as Path from "../../../../routes/GeneralPaths";
import * as Request from "../../../../api/Request";
import * as ApiUrl from "../../../../api/ApiUrl";
import * as Message from "../../../../helpers/Messages";
import * as ConfirmPopup from "../../../../helpers/ConfirmPopup";

const Tags = () => {
  const { t } = useTranslation("");
  const { siteId } = useParams();
  const navigate = useNavigate();
  const userData = useUserdata((state) => state.userData);

  const [tags, setTags] = useState([]);
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState({});
  const [btnLoading, setBtnLoading] = useState(false);
  const [activePage, setActivePage] = useState("");
  const [action, setAction] = useState(false);
  const [pagination, setPagination] = useState([]);
  const [tagId, setTagId] = useState("");
  const [tagModal, setTagModal] = useState(false);

  const setTagVisible = (visible) => {
    setTagModal(visible);
    setTagId("");
  };

  useEffect(() => {
    listTags();
  }, [action, activePage]);

  const listTags = () => {
    let request = ApiUrl.tagsList(siteId);

    var searchParams = {};

    if (activePage > 1) {
      searchParams = {
        page: activePage,
      };
    }

    request = SearchFilter(searchParams, request);

    Request.getRequest(request).then((response) => {
      if (response.status === 200) {
        setTags(response.data.tags.data);
        setLoading(false);
        setAction(false);
        setError([]);
        setPagination(response.data.tags);
        if (response.data.tags.data.length < 1 && activePage > 1) {
          setActivePage(activePage - 1);
          setAction(true);
        }
      } else if (response.status === 422) {
        setAction(false);
        setTimeout(() => {
          navigate(-1);
        }, 2000);
        setError(response.data);
      } else if (response.status === 404) {
        setAction(false);
        setTimeout(() => {
          navigate(-1);
        }, 2000);
        Message.errorMsg(response.data.error);
      } else {
        Message.errorMsg(t("error_message"));
        setTimeout(() => {
          navigate(-1);
        }, 2000);
      }
    });
  };

  const createTag = (e) => {
    e.preventDefault();
    setBtnLoading(true);

    var data = {
      name: name,
      site_id: siteId,
    };

    Request.postRequest(ApiUrl.tagStore, data).then((response) => {
      setBtnLoading(false);
      if (response.status === 200) {
        setAction(true);
        setName("");
        setActivePage("1");
        setError([]);
        Message.successMsg(response.data.success);
      } else if (response.status === 422) {
        setError(response.data);
      } else {
        navigate(Path.siteTagsId);
        Message.errorMsg(t("error_message"));
      }
    });
  };

  const deleteTag = (e, id, name) => {
    ConfirmPopup.deletePopup(name).then((response) => {
      if (response.isConfirmed) {
        Request.deleteRequest(ApiUrl.tagDelete(id)).then((response) => {
          if (response.status === 200) {
            Message.successMsg(response.data.success);
            setAction(true);
          } else if (response.status === 404) {
            Message.errorMsg(response.data.error);
          } else {
            Message.errorMsg(t("error_message"));
          }
        });
      }
    });
  };

  return (
    <>
      <p className="partial-title mb-5">{t("site_tags_title")}</p>
      {(userData.role === "superadmin" || userData.role === "admin") && (
        <div className="add-item pt-4 pb-2 px-4 mb-5">
          <form onSubmit={(e) => createTag(e)}>
            <div className="row">
              <label>{t("create_new_tag")}</label>
              <div className="form-block col-12 col-md-5 col-xxl-3">
                <input
                  placeholder={t("tag_name_placeholder")}
                  className={"form-input" + (error?.name ? " input-error" : "")}
                  name="name"
                  value={name}
                  type="text"
                  onChange={(e) => setName(e.target.value)}
                />
                {error?.name && <ValidationMessage message={error?.name[0]} />}
              </div>
              <div className="col-12 col-sm-6 col-md-4 pb-3">
                {btnLoading ? (
                  <button type="button" className="button-blue has-icon">
                    <i className="icon spinner-icon me-2" />
                    {t("tag_saving")}
                  </button>
                ) : (
                  <button type="submit" className="button-blue has-icon">
                    {t("tag_save")}
                  </button>
                )}
              </div>
            </div>
          </form>
        </div>
      )}
      <div className="table-list mb-5">
        <table role="table" className="w-100">
          <thead>
            <tr role="row">
              <th
                role="columnheader"
                className="text-left"
                style={
                  userData.role === "superadmin" || userData.role === "admin"
                    ? { width: "80%" }
                    : { width: "100%" }
                }
              >
                {t("tags_list_name")}
              </th>
              {(userData.role === "superadmin" ||
                userData.role === "admin") && (
                <th
                  role="columnheader"
                  className="text-center"
                  style={{ width: "20%" }}
                >
                  {t("tags_list_action")}
                </th>
              )}
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td
                  className="text-center"
                  colSpan={
                    userData.role === "superadmin" || userData.role === "admin"
                      ? "2"
                      : "1"
                  }
                >
                  <Loading />
                </td>
              </tr>
            ) : (
              tags.map((tag) => (
                <tr key={tag.id} role="row">
                  <td role="cell" data-label={t("tags_list_name")}>
                    {tag.name}
                  </td>
                  {(userData.role === "superadmin" ||
                    userData.role === "admin") && (
                    <td
                      role="cell"
                      data-label={t("tags_list_action")}
                      className="text-center"
                    >
                      <div className="row justify-content-center mr-auto ml-auto action-icons">
                        <div className="edit">
                          <button
                            className="button-unstyled-icon"
                            onClick={(e) => {
                              setTagModal(true);
                              setTagId(tag.id);
                            }}
                          >
                            <i className="icon edit-icon" />
                          </button>
                          {tagModal && tagId === tag.id && (
                            <EditTag
                              onShowModal={tagModal}
                              setTagVisible={setTagVisible}
                              setModified={setAction}
                              tagId={tag.id}
                              tagName={tag.name}
                            />
                          )}
                        </div>
                        <div className="delete">
                          <button
                            type="submit"
                            className="button-unstyled-icon"
                            onClick={(e) => {
                              deleteTag(e, tag.id, tag.name);
                            }}
                          >
                            <i className="icon delete-icon" />
                          </button>
                        </div>
                      </div>
                    </td>
                  )}
                </tr>
              ))
            )}
          </tbody>
        </table>
        <ListResponse
          totalItems={pagination && pagination.total}
          loading={loading}
          searched={false}
        />
      </div>
      <CustomPagination
        data={pagination}
        setActivePage={(e) => setActivePage(e)}
      />
    </>
  );
};

export default Tags;
