import React from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import Auth from "./Auth";
import ResetPassword from "../../components/authentication/ResetPassword";

const ResetPasswordView = () => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{t("reset_password_tab_title")}</title>
      </Helmet>
      <div className="d-flex flex-wrap login-view" style={{ width: 100 + "%" }}>
        <Auth />
        <div className="right-auth-page">
          <ResetPassword />
        </div>
      </div>
    </>
  );
};

export default ResetPasswordView;
