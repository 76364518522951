import { useTranslation } from "react-i18next";
import { AnimatePresence, motion } from "framer-motion";
import "../../assets/scss/main.scss";

const Features = () => {
  const { t } = useTranslation();

  return (
    <div id="features">
      <AnimatePresence>
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          transition={{ duration: 0.3 }}
          variants={{
            visible: { opacity: 1, y: 0 },
            hidden: { opacity: 0, y: 200 },
          }}
          className="features container d-flex flex-column text-center"
        >
          <div className="features-headings d-flex flex-column align-items-center pb-5">
            <h1 className="fw-bold pb-2">{t("homepage_features_title")}</h1>
            <h5 className="text-center col-lg-5 col-md-8 col-sm-10 pb-5">
              <strong>{t("homepage_features_subtitle_strong")}</strong>
              {t("homepage_features_subtitle")}
            </h5>
          </div>
          <div className="features-row">
            <motion.div
              initial="hidden"
              whileInView="visible"
              whileHover="hover"
              viewport={{ once: true }}
              transition={{ duration: 0.5 }}
              variants={{
                visible: { opacity: 1, y: 0 },
                hover: { y: -7, transition: { duration: 0.2 } },
              }}
              className="features-col"
            >
              <div className="features-card card d-flex align-items-center p-2 position-relative">
                <div className="features-card-icon position-absolute rounded-circle " />
                <div className="card-body d-flex align-items-center flex-column">
                  <h5 className="features-card-title  pt-4 pb-2 fs-4">
                    {t("homepage_features_document_management")}
                  </h5>
                  <p className="features-card-paragraph lh-md text-center fs-6">
                    {t("homepage_features_document_management_description")}
                  </p>
                </div>
              </div>
            </motion.div>
            <motion.div
              initial="hidden"
              whileInView="visible"
              whileHover="hover"
              viewport={{ once: true }}
              transition={{ duration: 0.7 }}
              variants={{
                visible: { opacity: 1, y: 0 },
                hidden: { opacity: 0, y: 200 },
                hover: { y: -7, transition: { duration: 0.2 } },
              }}
              className="features-col"
            >
              <div className="features-card card d-flex align-items-center p-2 position-relative">
                <div className="features-card-icon position-absolute rounded-circle " />
                <div className="card-body d-flex align-items-center flex-column">
                  <h5 className="features-card-title pt-4 pb-2 fs-4">
                    {t("homepage_features_access_control")}
                  </h5>
                  <p className="features-card-paragraph lh-md text-center fs-6">
                    {t("homepage_features_access_control_description")}
                  </p>
                </div>
              </div>
            </motion.div>
            <motion.div
              initial="hidden"
              whileInView="visible"
              whileHover="hover"
              viewport={{ once: true }}
              transition={{ duration: 0.9 }}
              variants={{
                visible: { opacity: 1, y: 0 },
                hidden: { opacity: 0, y: 200 },
                hover: { y: -7, transition: { duration: 0.2 } },
              }}
              className="features-col"
            >
              <div className="features-card card d-flex align-items-center p-2 position-relative">
                <div className="features-card-icon position-absolute rounded-circle " />
                <div className="card-body d-flex align-items-center flex-column">
                  <h5 className="features-card-title pt-4 pb-2 fs-4">
                    {t("homepage_features_cloud_based")}
                  </h5>
                  <p className="features-card-paragraph lh-md text-center fs-6">
                    {t("homepage_features_cloud_based_description")}
                  </p>
                </div>
              </div>
            </motion.div>
            <motion.div
              initial="hidden"
              whileInView="visible"
              whileHover="hover"
              viewport={{ once: true }}
              transition={{ duration: 1.1 }}
              variants={{
                visible: { opacity: 1, y: 0 },
                hidden: { opacity: 0, y: 200 },
                hover: { y: -7, transition: { duration: 0.2 } },
              }}
              className="features-col"
            >
              <div className="features-card card d-flex align-items-center p-2 position-relative">
                <div className="features-card-icon position-absolute rounded-circle " />
                <div className="card-body d-flex align-items-center flex-column">
                  <h5 className="features-card-title pt-4 pb-2 fs-4">
                    {t("homepage_features_customization")}
                  </h5>
                  <p className="features-card-paragraph lh-md text-center fs-6">
                    {t("homepage_features_customization_description")}
                  </p>
                </div>
              </div>
            </motion.div>
            <motion.div
              initial="hidden"
              whileInView="visible"
              whileHover="hover"
              viewport={{ once: true }}
              transition={{ duration: 1.3 }}
              variants={{
                visible: { opacity: 1, y: 0 },
                hidden: { opacity: 0, y: 200 },
                hover: { y: -7, transition: { duration: 0.2 } },
              }}
              className="features-col"
            >
              <div className="features-card card d-flex align-items-center p-2 position-relative">
                <div className="features-card-icon position-absolute rounded-circle " />
                <div className="card-body d-flex align-items-center flex-column">
                  <h5 className="features-card-title pt-4 pb-2 fs-4">
                    {t("homepage_features_storage")}
                  </h5>
                  <p className="features-card-paragraph lh-md text-center fs-6">
                    {t("homepage_features_storage_description")}
                  </p>
                </div>
              </div>
            </motion.div>
            <motion.div
              initial="hidden"
              whileInView="visible"
              whileHover="hover"
              viewport={{ once: true }}
              transition={{ duration: 1.5 }}
              variants={{
                visible: { opacity: 1, y: 0 },
                hidden: { opacity: 0, y: 200 },
                hover: { y: -7, transition: { duration: 0.2 } },
              }}
              className="features-col"
            >
              <div className="features-card card d-flex align-items-center p-2 position-relative">
                <div className="features-card-icon position-absolute rounded-circle " />
                <div className="card-body d-flex align-items-center flex-column">
                  <h5 className="features-card-title pt-4 pb-2 fs-4">
                    {t("homepage_features_security")}
                  </h5>
                  <p className="features-card-paragraph lh-md text-center fs-6">
                    {t("homepage_features_security_description")}
                  </p>
                </div>
              </div>
            </motion.div>
          </div>
        </motion.div>
      </AnimatePresence>
    </div>
  );
};
export default Features;
