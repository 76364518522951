import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import ValidationMessage from "../../../../../helpers/ValidationMessage";

import * as Request from "../../../../../api/Request";
import * as ApiUrl from "../../../../../api/ApiUrl";
import * as Message from "../../../../../helpers/Messages";

const AddNewItem = ({ onShowModal, setItemVisible, setModified }) => {
  const { t } = useTranslation("");
  const { classificationId } = useParams();

  const [name, setName] = useState("");
  const [validations, setValidations] = useState({});
  const [btnLoading, setLoadingBtn] = useState(false);

  const onClose = () => {
    setName("");
    setValidations({});
    setItemVisible(false);
  };

  const addNewItem = (e) => {
    e.preventDefault();
    var data = {
      name: name,
      classification_id: classificationId,
    };
    setLoadingBtn(true);
    Request.postRequest(ApiUrl.addItem, data).then((response) => {
      setLoadingBtn(false);
      if (response.status === 200) {
        Message.successMsg(response.data.success);
        onClose();
        setModified(true);
      } else if (response.status === 422) {
        setValidations(response.data);
      } else {
        onClose();
        Message.errorMsg(t("error_message"));
      }
    });
  };

  return (
    <Modal isOpen={onShowModal} toggle={(e) => onClose(e)} size="md" centered>
      <ModalHeader
        className="text-dark font-size-15"
        toggle={(e) => onClose(e)}
        tag="span"
      >
        {t("add_item_popup_title")}
      </ModalHeader>
      <form onSubmit={(e) => addNewItem(e)} className="text-center">
        <ModalBody>
          <div className="form-block row mb-0">
            <div className="col-12 mb-3">
              <input
                type="text"
                name="name"
                className={
                  "form-input" + (validations?.name ? " input-error" : "")
                }
                onChange={(e) => setName(e.target.value)}
                value={name}
                placeholder={t("add_item_popup_placeholder")}
              />
              {validations?.name && (
                <ValidationMessage message={validations?.name} />
              )}
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="col-12 d-flex m-0 p-0">
            <div className="col-6 pe-2">
              {btnLoading ? (
                <button className="button-blue w-100 has-icon" type="button">
                  <i className="icon spinner-icon me-2" />
                  {t("add_item_editing_button")}
                </button>
              ) : (
                <button type="submit" className="button-blue w-100">
                  {t("add_item_edit_button")}
                </button>
              )}
            </div>
            <div className="col-6 ps-2">
              <button
                className="button-blue-border w-100"
                type="button"
                onClick={() => onClose()}
              >
                {t("add_item_cancel_button")}
              </button>
            </div>
          </div>
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default AddNewItem;
