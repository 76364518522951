import React, { useEffect, useCallback, useMemo } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { helperRoutes, privateRoutes, publicRoutes } from "./Routes";
import { useUserdata } from "../store/UserData";
import ScrollToTop from "../helpers/ScrollToTop";

import * as Request from "../api/Request";
import * as ApiUrl from "../api/ApiUrl";
import * as AppConfig from "../helpers/AppConfig";
import * as Path from "../routes/GeneralPaths";

const AppRouter = () => {
  const addLoggedUserData = useUserdata((state) => state.addUserData);
  const role = useUserdata((state) => state.userData.role);

  const userData = useCallback(async () => {
    try {
      const response = await Request.postRequest(ApiUrl.profileData);
      const { data } = response;
      if (response.status === 200) {
        addLoggedUserData(data);
      }
    } catch (error) {
      window.location.href = Path.login;
      AppConfig.deleteAccessToken();
    }
  }, [addLoggedUserData]);

  useEffect(() => {
    if (AppConfig.isLogged()) {
      userData();
    }
  }, [userData]);

  useEffect(() => {
    const storageListener = (event) => {
      if (
        event.storageArea === localStorage &&
        (event.key === "access_token" || event.key === "role")
      ) {
        if (event.oldValue !== null && event.newValue !== event.oldValue) {
          Request.postRequest(ApiUrl.logout).then((response) => {
            if (response.status === 200) {
              AppConfig.deleteAccessToken();
            } else {
              AppConfig.deleteAccessToken();
            }
          });
        }
      }
    };

    window.addEventListener("storage", storageListener);

    return () => {
      window.removeEventListener("storage", storageListener);
    };
  }, []);

  const memoizedPrivateRoutes = useMemo(() => privateRoutes(role), [role]);

  return (
    <Router>
      <ScrollToTop />
      <Routes>
        {memoizedPrivateRoutes.map((route, idx) => (
          <Route exact path={route.path} element={route.element} key={idx} />
        ))}
        {publicRoutes().map((route, idx) => (
          <Route exact path={route.path} element={route.element} key={idx} />
        ))}
        {helperRoutes.map((route, idx) => (
          <Route path={route.path} element={route.element} key={idx} />
        ))}
      </Routes>
    </Router>
  );
};

export default AppRouter;
