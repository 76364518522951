import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";

export const tooltipDisabledMobile = (index, title) => {
  let screenWidth = window.innerWidth;
  let titleLength = title?.length;

  if (screenWidth < 768) {
    return (
      <div
        className={"title-tooltip-" + index}
        id={"tooltip" + index}
        style={{ width: "100%" }}
      >
        {title}
      </div>
    );
  } else if (screenWidth > 767 && titleLength >= 18) {
    return (
      <div
        className={"title-tooltip-" + index}
        id={"tooltip" + index}
        data-tooltip-id={"toolTip" + index}
        style={{ width: "fit-content" }}
      >
        {title.slice(0, 15) + "..."}
        <Tooltip
          id={"toolTip" + index}
          data-tooltip-place="top"
          float="solid"
          style={{ width: "35%" }}
        >
          {title}
        </Tooltip>
      </div>
    );
  } else if (screenWidth > 767 && titleLength < 18) {
    return <div className={"title-tooltip-" + index}>{title}</div>;
  }
};

export const tooltip = (index, title, text) => {
  return (
    <div id={"tooltip" + index} data-tip data-for={"toolTip" + index}>
      {title}
      <Tooltip id={"toolTip" + index} place="top" effect="solid">
        {text}
      </Tooltip>
    </div>
  );
};

export const disabledTooltipBtn = (id, title, text) => {
  return (
    <>
      <div
        className="button-blue"
        id={id}
        data-tip
        data-for={id}
        style={{ width: "fit-content", cursor: "pointer" }}
      >
        {title}
      </div>
      <Tooltip id={id} place="top" effect="solid">
        {text}
      </Tooltip>
    </>
  );
};

export const TooltipDesc = (index, title) => {
  let screenWidth = window.innerWidth;
  let titleLength = title.length;

  if (screenWidth < 768) {
    return (
      <div
        className="title-tooltip"
        id={"tooltip" + index}
        style={{ width: "100%" }}
      >
        {title}
      </div>
    );
  } else if (screenWidth > 767 && titleLength >= 40) {
    return (
      <div
        className="title-tooltip"
        id={"tooltip" + index}
        data-tooltip-id={"toolTip" + index}
        style={{ width: "fit-content" }}
        data-tooltip-place="top"
      >
        {title.slice(0, 37) + "..."}
        <Tooltip id={"toolTip" + index} style={{ maxWidth: "300px" }}>
          {title}
        </Tooltip>
      </div>
    );
  } else if (screenWidth > 767 && titleLength < 40) {
    return <div className={"title-tooltip"}>{title}</div>;
  }
};
