import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import ValidationMessage from "../../../../../helpers/ValidationMessage";
import Select from "react-select";
import Loading from "../../../../../helpers/Loading";

import * as Request from "../../../../../api/Request";
import * as ApiUrl from "../../../../../api/ApiUrl";
import * as Message from "../../../../../helpers/Messages";

const UploadFile = ({ onShowModal, setFileVisible, setAction }) => {
  const { t } = useTranslation("");
  const { siteId, parentId } = useParams();
  
  const [name, setName] = useState("");
  const [path, setPath] = useState(null);
  const [detailsList, setDetailsList] = useState([]);
  const [items, setItems] = useState({});
  const [tagsList, setTagsList] = useState([]);
  const [tags, setTags] = useState([]);
  const [loading, setLoading] = useState(true);
  const [btnLoading, setBtnLoading] = useState(false);
  const [error, setError] = useState([]);

  useEffect(() => getDetails(), []);

  const onClose = () => {
    setName("");
    setPath(null);
    setTags([]);
    setItems({});
    setDetailsList([]);
    setTagsList([]);
    setFileVisible(false);
    setError([]);
  };

  const uploadFile = (e) => {
    e.preventDefault();
    setBtnLoading(true);

    var formData = new FormData();
    formData.append("name", name);
    if (path) {
      formData.append("path", path);
    }
    tags.forEach((tag, index) => {
      formData.append("tags[" + index + "]", tag.value);
    });
    Object.keys(items).forEach((value, index) => {
      if (items[value] !== "") {
        formData.append(`items[${index}]`, items[value]);
      }
    });
    if (parentId) {
      formData.append("folder_id", parentId);
    }
    formData.append("site_id", siteId);
    formData.append("_method", "POST");

    Request.postRequestExport(ApiUrl.storeDocument, formData).then(
      (response) => {
        setBtnLoading(false);
        if (response.status === 200) {
          Message.successMsg(response.data.success);
          onClose();
          setAction(true);
          setError([]);
        } else if (response.status === 422) {
          setError(response.data);
        } else if (response.status === 404) {
          Message.errorMsg(response.data.error);
        } else {
          Message.errorMsg(t("error_message"));
        }
      }
    );
  };

  const getDetails = () => {
    setBtnLoading(false);
    Request.getRequest(ApiUrl.documentDetails(siteId)).then((response) => {
      if (response.status === 200) {
        setDetailsList(response.data.classifications);
        setTagsList(response.data.tags);
        setLoading(false);
      }
    });
  };

  return (
    <Modal isOpen={onShowModal} toggle={(e) => onClose(e)} size="lg" centered>
      <ModalHeader
        className="text-dark font-size-15"
        toggle={(e) => onClose(e)}
        tag="span"
      >
        {t("upload_document_popup_title")}
      </ModalHeader>
      <form onSubmit={(e) => uploadFile(e)} className="text-center">
        <ModalBody>
          <div className="row">
            <div className="form-block col-12 col-md-6">
              <label className="d-flex text-left">
                {t("upload_document_file")}
              </label>
              <div className="upload-file-box">
                <div
                  className={"form-input" + (error.path ? " input-error" : "")}
                >
                  {path === null ? (
                    <>
                      <input
                        className="upload-input"
                        onChange={(e) => setPath(e.target.files[0])}
                        name="path"
                        type="file"
                      />
                      <div className="upload-input-placeholder">
                        <span>{t("upload_document_popup_placeholder")}</span>
                        <i className="icon upload-icon" />
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="upload-input-placeholder">
                        <span>{path.name}</span>
                        <i
                          className="icon close-red-icon"
                          onClick={() => setPath(null)}
                        />
                      </div>
                    </>
                  )}
                </div>
              </div>
              {error?.path && <ValidationMessage message={error?.path} />}
            </div>
            <div className="form-block col-12 col-md-6">
              <label className="d-flex text-left">
                {t("upload_document_custom_name")}
              </label>
              <input
                type="text"
                name="name"
                className={"form-input" + (error?.name ? " input-error" : "")}
                onChange={(e) => setName(e.target.value)}
                value={name}
                placeholder={t("upload_document_name_popup_placeholder")}
              />
              {error?.name && <ValidationMessage message={error?.name} />}
            </div>
          </div>
          <div className="dropdown-file">
            {loading ? (
              <div className="text-center" colSpan="2">
                <Loading />
              </div>
            ) : (
              detailsList.map((item) => (
                <div className="form-block" key={item.id}>
                  <label className="d-flex text-left">{item.name}</label>
                  <Select
                    className={
                      "form-react-select" + (error.items ? " input-error" : "")
                    }
                    classNamePrefix="select_input"
                    name="classification"
                    isClearable={true}
                    placeholder={t("upload_document_choose_items")}
                    onChange={(e) =>
                      setItems({
                        ...items,
                        [`item[${item.id}]`]: e ? e.value : "",
                      })
                    }
                    isSearchable={true}
                    options={item.items.map((items) => ({
                      value: items.id,
                      label: items.name,
                    }))}
                  />
                  {error.items && (
                    <ValidationMessage message={error.items[0]} />
                  )}
                </div>
              ))
            )}
          </div>
          <div className="form-block row mb-0">
            <label className="d-flex text-left">
              {t("upload_documents_tags")}
            </label>
            <Select
              className={
                "form-react-select" + (error.tags ? " input-error" : "")
              }
              isMulti
              classNamePrefix="select_input"
              name="tags"
              isClearable={true}
              placeholder={t("upload_document_choose_tags")}
              onChange={(e) => (e ? setTags(e) : setTags([]))}
              value={tags}
              isSearchable={true}
              options={tagsList.map((tag) => ({
                value: tag.id,
                label: tag.name,
              }))}
            />
            {error.tags && <ValidationMessage message={error.tags[0]} />}
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="col-12 d-flex m-0 p-0">
            <div className="col-6 pe-2 text-end">
              {btnLoading ? (
                <button className="button-blue has-icon" type="button">
                  <i className="icon spinner-icon me-2" />
                  {t("upload_saving_button")}
                </button>
              ) : (
                <button type="submit" className="button-blue">
                  {t("upload_save_button")}
                </button>
              )}
            </div>
            <div className="col-6 ps-2 text-start">
              <button
                className="button-blue-border"
                type="button"
                onClick={() => onClose()}
              >
                {t("upload_document_cancel_button")}
              </button>
            </div>
          </div>
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default UploadFile;
