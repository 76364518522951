import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import ValidationMessage from "../../helpers/ValidationMessage";

import * as Request from "../../api/Request";
import * as ApiUrl from "../../api/ApiUrl";
import * as Path from "../../routes/GeneralPaths";
import * as Message from "../../helpers/Messages";

const Register = () => {
  const { t } = useTranslation();
  const { token } = useParams();
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [roleName, setRoleName] = useState("");
  const [roleId, setRoleId] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [companyDescription, setCompanyDescription] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [email, setEmail] = useState("");
  const [errorList, setErrorList] = useState([]);
  const [btnLoading, setBtnLoading] = useState(false);

  useEffect(() => {
    getUserData();
  }, []);

  const getUserData = () => {
    Request.getRequest(ApiUrl.acceptInvitation + token).then((response) => {
      if (response.status === 200) {
        setRoleId(response.data.user.role.id);
        setRoleName(response.data.user.role.name);
        response.data.user.company &&
          setCompanyName(response.data.user.company.name);
        setEmail(response.data.user.email);
      } else {
        navigate(Path.login);
      }
    });
  };

  const activateAccount = (e) => {
    e.preventDefault();
    setBtnLoading(true);

    const formData = {
      role_id: roleId,
      first_name: firstName,
      last_name: lastName,
      phone: phone,
      company_name: companyName,
      company_description: companyDescription,
      password: password,
      password_confirmation: confirmPassword,
    };

    Request.postRequest(ApiUrl.completeRegistration + token, formData).then(
      (response) => {
        setBtnLoading(false);
        if (response.status === 200) {
          Message.successMsg(response.data.success);
          navigate(Path.login);
        } else if (response.status === 404 || response.status === 422) {
          setErrorList(response.data);
        }
      }
    );
  };

  return (
    <div className="form-logins">
      <div className="login_bg">
        <div className="w-100 user-data">
          <div className="user-info">
            <span className="email">{`${email}`}</span>
          </div>
        </div>
        <form className="w-100" onSubmit={(e) => activateAccount(e)}>
          {roleName !== "superadmin" && (
            <>
              <label>{t("activate_account_company_name_label")}</label>
              <div className="form-group">
                <input
                  type="text"
                  name="company_name"
                  placeholder={t("activate_account_company_name_placeholder")}
                  value={companyName}
                  className={
                    "form-input" +
                    (errorList.company_name ? " input-error" : "")
                  }
                  autoComplete="off"
                  onChange={(e) =>
                    roleName === "admin" ? setCompanyName(e.target.value) : ""
                  }
                  readOnly={roleName === "admin" ? false : true}
                />
                <i className="icon company-icon" />
                {errorList.company_name && (
                  <ValidationMessage message={errorList.company_name[0]} />
                )}
              </div>
              {roleName === "admin" && (
                <>
                  <label>
                    {t("activate_account_company_description_label")}
                  </label>
                  <div className="form-group">
                    <input
                      type="text"
                      name="company_description"
                      placeholder={t(
                        "activate_account_company_description_placeholder"
                      )}
                      value={companyDescription}
                      className={
                        "form-input" +
                        (errorList.company_description ? " input-error" : "")
                      }
                      autoComplete="off"
                      onChange={(e) => setCompanyDescription(e.target.value)}
                    />
                    <i className="icon company-icon" />
                    {errorList.company_description && (
                      <ValidationMessage
                        message={errorList.company_description[0]}
                      />
                    )}
                  </div>
                </>
              )}
            </>
          )}
          <label>{t("activate_account_first_name_label")}</label>
          <div className="form-group">
            <input
              type="text"
              name="first_name"
              placeholder={t("activate_account_first_name_placeholder")}
              value={firstName}
              className={
                "form-input" + (errorList.first_name ? " input-error" : "")
              }
              autoComplete="off"
              onChange={(e) => setFirstName(e.target.value)}
            />
            <i className="icon username-icon" />
            {errorList.first_name && (
              <ValidationMessage message={errorList.first_name[0]} />
            )}
          </div>
          <label>{t("activate_account_last_name_label")}</label>
          <div className="form-group">
            <input
              type="text"
              name="last_name"
              placeholder={t("activate_account_last_name_placeholder")}
              value={lastName}
              className={
                "form-input" + (errorList.last_name ? " input-error" : "")
              }
              autoComplete="off"
              onChange={(e) => setLastName(e.target.value)}
            />
            <i className="icon username-icon" />
            {errorList.last_name && (
              <ValidationMessage message={errorList.last_name[0]} />
            )}
          </div>
          <label>{t("activate_account_phone_label")}</label>
          <div className="form-group">
            <input
              type="number"
              name="phone"
              placeholder={t("activate_account_phone_placeholder")}
              value={phone}
              className={"form-input" + (errorList.phone ? " input-error" : "")}
              autoComplete="off"
              onChange={(e) => setPhone(e.target.value)}
            />
            <i className="icon phone-icon" />
            {errorList.phone && (
              <ValidationMessage message={errorList.phone[0]} />
            )}
          </div>
          <label>{t("activate_account_password_label")}</label>
          <div className="form-group">
            <input
              type={!showPassword ? "password" : "text"}
              name="password"
              placeholder={t("activate_account_password_placeholder")}
              value={password}
              className={
                "form-input" + (errorList.password ? " input-error" : "")
              }
              autoComplete="off"
              onChange={(e) => setPassword(e.target.value)}
            />
            <i className="icon lock-icon" />
            <div
              className="show-hide"
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? (
                <i className="icon show-icon" />
              ) : (
                <i className="icon hide-icon" />
              )}
            </div>
            {errorList.password && (
              <ValidationMessage message={errorList.password[0]} />
            )}
          </div>
          <label>{t("activate_account_confirm_password_label")}</label>
          <div className="form-group">
            <input
              type={!showConfirmPassword ? "password" : "text"}
              name="password_confirmation"
              placeholder={t("activate_account_confirm_password_placeholder")}
              value={confirmPassword}
              className={
                "form-input" + (errorList.password ? " input-error" : "")
              }
              autoComplete="off"
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
            <i className="icon lock-icon" />
            <div
              className="show-hide"
              onClick={() => setShowConfirmPassword(!showConfirmPassword)}
            >
              {showConfirmPassword ? (
                <i className="icon show-icon" />
              ) : (
                <i className="icon hide-icon" />
              )}
            </div>
          </div>
          {btnLoading ? (
            <button type="button" className="button-blue has-icon w-100 mt-2">
              <i className="spinner-icon" />
              {t("activate_account_btn_loading")}
            </button>
          ) : (
            <button type="submit" className="button-blue w-100 mt-2">
              {t("activate_account_btn")}
            </button>
          )}
        </form>
      </div>
    </div>
  );
};

export default Register;
